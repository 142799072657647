import Vue from "vue";
import VueI18n from "vue-i18n";
import de from "../locales/de-DE.json";
import axios from "axios";
import moment from "moment";
import vuetify from "./vuetify";
import { headerAcceptLanguage } from "timepowerweb-common/config";

Vue.use(VueI18n);

export const storageKeyLang = "language";

const languageGerman = "de-DE";
const languageEnglish = "en-US";

export const defaultLanguage = languageGerman;
export const availableLanguages = [defaultLanguage, languageEnglish];

export const i18n = new VueI18n({
    locale: defaultLanguage,
    fallbackLocale: defaultLanguage,
    messages: {
        [languageGerman]: {
            ...de,
        },
    },
    silentFallbackWarn: Vue.config.devtools,
});

setI18nLanguage(defaultLanguage);

/**
 *
 */
function getLoadedLanguages(): string[] {
    return Object.keys(i18n.messages);
}

/**
 *
 * @param lang
 */
function setI18nLanguage(lang: string): string {
    i18n.locale = lang;
    axios.defaults.headers.common[headerAcceptLanguage] = getLocaleToUrlLocale(lang);

    const locale = getBrowserMainLanguage(); //"en";
    moment.locale(locale);

    vuetify.framework.lang.current = getLocaleToUrlLocale(lang);
    return lang;
}

/**
 *
 * @param locale
 */
export function getLocaleToUrlLocale(locale: string): string {
    return locale.substring(0, 2);
}

/**
 *
 * @param locale
 */
export function getUrlLocaleToLocale(locale: string): string | undefined {
    return availableLanguages.find((x) => x.startsWith(locale));
}

/**
 *
 */
export function toggleBetweenLanguages(): string {
    return i18n.locale === languageGerman ? languageEnglish : languageGerman;
}

/**
 *
 */
function getBrowserMainLanguage(): string {
    return window.navigator.language;
}

/**
 *
 */
export function getLanguage(): string {
    return localStorage.getItem(storageKeyLang) ?? getBrowserMainLanguage();
}

/**
 *
 * @param lang
 */
export function loadLanguageAsync(lang: string): Promise<string> {
    if (lang === undefined) {
        return Promise.resolve("No language defined to be set!");
    }

    // If the same language or language was already loaded:
    if (i18n.locale === lang
        || getLoadedLanguages().includes(lang)) {
        return Promise.resolve(setI18nLanguage(lang));
    }

    // If the language hasn't been loaded yet
    return import(`../locales/${lang}.json`)
        .then((messages) => {
            i18n.setLocaleMessage(lang, messages.default);
            return setI18nLanguage(lang);
        });
}
