import Vue from "vue";
import App from "./views/app.vue";
import store from "./store";
import vuetify from "./plugins/vuetify"; // path to vuetify export
import { i18n } from "./plugins/i18n";
import { router } from "./plugins/router";
import "./plugins/toast";

Vue.config.productionTip = false;

const vm = new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");

export { vm };
