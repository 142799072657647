import { plainToInstance } from "class-transformer";
import { Company } from "timepowerweb-common/model/company-model";
import { InternalOrderFavorites, InternalOrderFavoritesList } from "timepowerweb-common/model/internalorderfavorites-model";
import { TimepowerEntry, TimepowerEntryList } from "timepowerweb-common/model/timepowerentry-model";
import { INTERNALORDER_FAVORITES_ROUTE, INTERNALORDER_TREE_ROUTE } from "timepowerweb-common/routes";
import axios, { ComposedError } from "../helper/axios";

/**
 *
 */
export default class InternalOrderService {
    static getTimepowerEntryList(company: Company): Promise<TimepowerEntryList> {
        return axios.get(INTERNALORDER_TREE_ROUTE + company.id)
            .then((res) => plainToInstance(TimepowerEntry, res.data as []))
            .catch((error: ComposedError) => {
                // do not handle it globally because it will throw
                // an error toast on failed login
                throw error.handleGlobally("getInternalOrderTree");
            });
    }
    static getFavoritesList(company: Company): Promise<InternalOrderFavoritesList> {
        return axios.get(INTERNALORDER_FAVORITES_ROUTE + company.id)
            .then((res) => plainToInstance(InternalOrderFavorites, res.data as []))
            .catch((error: ComposedError) => {
                // do not handle it globally because it will throw
                // an error toast on failed login
                throw error.handleGlobally("getFavorites");
            });
    }
}
