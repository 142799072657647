import { plainToInstance } from "class-transformer";
import { PepWarning, PepWarningList } from "timepowerweb-common/model/pepwarning-model";
import { PEP_COMPANY_ROUTE } from "timepowerweb-common/routes";
import axios, { ComposedError } from "../helper/axios";
import companyModule from "../store/modules/company-module";

/**
 *
 */
export default class PepWarningService {
    static getPepWarnings(): Promise<PepWarningList> {
        if (companyModule.company) {
            return axios.get(PEP_COMPANY_ROUTE + companyModule.company.id)
                .then((res) => plainToInstance(PepWarning, res.data as []))
                .catch((error: ComposedError) => {
                    throw error.handleGlobally("getPepWarnings");
                });
        } else {
            return Promise.resolve([]);
        }
    }
}
