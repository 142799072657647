<template>
    <v-card class="mb-4 text-left">
        <v-card-title>
            {{ title }}
            <v-spacer />
            <span class="text-right" :class="color">{{ eventType }}</span>
        </v-card-title>
        <v-card-text>
            <v-text-field
                :label="$t('object.event.costCenter')"
                readonly
                dense
                outlined
                :value="costCenter"
            />
            <v-text-field
                :label="$t('object.event.internalOrder')"
                dense
                readonly
                outlined
                :value="internalOrder"
            />
            <v-text-field
                :label="$t('object.event.workType')"
                readonlys
                dense
                outlined
                :value="workType"
            />
            <span v-if="isHomeOfficeStatusChangeable">
                {{ $t("object.event.workLocationLabel") }}
            </span>
            <v-switch
                class="mt-0"
                v-model="event.isInHomeOffice"
                :label="labelWorkStatus"
                hide-details
                v-if="isHomeOfficeStatusChangeable"
            >
                <template v-slot:label>
                    <span class="accent--text">
                        {{ labelWorkStatus }}
                    </span>
                </template>
            </v-switch>
            <v-dialog
                persistent
                :value="showWorkStatusHint"
                v-if="isHomeOfficeStatusChangeable"
            >
                <v-card flat color="error" class="tooltipWorkAtHome">
                    <v-card-text class="mb-0 pb-0 pt-2 white--text">
                        {{ workStatusHint }}<br />
                        {{
                            $t("object.user.settings.workStatusHintAdditional")
                        }}
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer />
                        <v-btn @click="setStatusHintsPermanently(false)" small>
                            {{ $t("site.general.removeItPermanently") }}
                        </v-btn>
                        <v-btn @click="setStatusHints(false)" small>
                            {{ $t("site.general.gotIt") }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-text-field
                :label="$t('object.event.workLocationLabel')"
                readonly
                dense
                outlined
                hide-details
                :value="labelWorkStatus"
                v-if="!isHomeOfficeStatusChangeable"
            />
        </v-card-text>
    </v-card>
</template>

<script lang="ts">
import Vue from "vue";
import { CostCenter } from "timepowerweb-common/model/costcenter-model";
import { EnumEventType, EventTimepower } from "timepowerweb-common/model/event-model";
import { eventTypeToString } from "../helper/event-helper";
import { i18n } from "../plugins/i18n";
import EventModule from "../store/modules/event-module";
import { localShowHint } from "../store/modules/user-module";

export enum StatusType {
    Current = 0,
    Next = 1,
}

export default Vue.extend({
    name: "StatusComponent",
    props: [
        "type",
        "event",
    ],
    computed: {
        labelWorkStatus() {
            return (this.event as EventTimepower).isInHomeOffice ? i18n.t("object.user.settings.workStatusAtHome") : i18n.t("object.user.settings.workStatusInOffice");
        },
        workStatusHint() {
            return i18n.t("object.user.settings.workStatusHint", [i18n.t("site.general.apply"), i18n.t("object.user.settings.workStatusInOffice"), i18n.t("object.user.settings.workStatusAtHome")]);
        },
        isHomeOfficeStatusChangeable() {
            return this.type == StatusType.Next
                && EventModule.currentEvent.eventType !== (this.event as EventTimepower).eventType
                && [EnumEventType.TrackingChanged, EnumEventType.TrackingActive].includes((this.event as EventTimepower).eventType);
        },
        title() {
            if (this.type == StatusType.Current) {
                return this.$i18n.t("site.component.status.current");
            } else {
                return this.$i18n.t("site.component.status.next");
            }
        },
        color() {
            if (this.type == StatusType.Current) {
                return "red--text font-weight-bold";
            }
            else {
                return "green--text font-weight-bold";
            }
        },
        costCenter() {
            return ((this.event as EventTimepower).internalOrder.costCenter as CostCenter)?.description ?? "";
        },
        internalOrder() {
            return (this.event as EventTimepower).internalOrder.description ?? "";
        },
        workType() {
            return (this.event as EventTimepower).workType.name ?? "";
        },
        eventType() {
            return eventTypeToString((this.event as EventTimepower).eventType);
        },
    },
    data: () => ({
        showWorkStatusHint: localStorage.getItem(localShowHint) != "false",
    }),
    methods: {
        setStatusHints(value: boolean) {
            console.log("setStatusHints");
            this.showWorkStatusHint = value;
        },
        setStatusHintsPermanently(value: boolean) {
            localStorage.setItem(localShowHint, value ? "true" : "false");
            this.setStatusHints(value);
        },
    },
});

</script>

<style>
.tooltipWorkAtHome {
    position: absolute;
    bottom: 60px;
    right: 30px;
    max-width: 750px;
}
</style>
