import store from "..";
import { Module, VuexModule, Mutation, Action, getModule } from "vuex-module-decorators";
import UserService from "../../services/user-service";
import { catStore } from "../../helper/logging";
import { ChangePassword, DefaultResult, LoginResponse, LoginUser, User, UserSettings } from "timepowerweb-common/model/user-model";
import { StateTime } from "timepowerweb-common/model/statetime-model";
import { i18n } from "../../plugins/i18n";
import { JwtHelper } from "../../helper/helper";

export const localTokenName = "tpw_api_token";
export const localShowHint = "tpw_show_hint";

/**
 *
 */
@Module({
    name: "user",
    store,
    namespaced: true,
    dynamic: true,
})
class UserModule extends VuexModule {
    token = localStorage.getItem(localTokenName) || null;
    user = this._loggedOffUser();

    get isLoggedIn(): boolean {
        return this.token !== null;
    }

    get userDisplayName(): string {
        if (this.isLoggedIn && this.user) {
            return this.user.firstName + " " + this.user.lastName;
        } else {
            return i18n.t("object.user.loggedOff.name").toString();
        }
    }

    @Action({ commit: "storeUser" })
    private _loggedOffUser(): User {
        return {
            id: "-1",
            firstName: "",
            lastName: "",
            settings: new UserSettings(),
        };
    }

    @Action({ commit: "updateSettings" })
    retrieveUserSettings(): Promise<UserSettings> {
        return UserService.retrieveUserSettings();
    }

    @Action
    updateUser(): Promise<User> {
        if (this.isLoggedIn) {
            return this.retrieveUser();
        } else {
            return Promise.resolve(this._loggedOffUser());
        }
    }

    @Action({ commit: "storeUser" })
    updateUserSettings(settings?: UserSettings) {
        return UserService.updateUserSettings(settings ?? this.user.settings);
    }

    @Action
    loginUser(user: LoginUser): Promise<LoginResponse> {
        return UserService.login(user)
            .then((response: LoginResponse) => {

                // save token
                catStore.debug("Received token " + response.token);
                catStore.debug(`Received token: ${JSON.stringify(JwtHelper.decodeToken(response.token))};`);

                //go back to login view
                return response;
            });
    }

    @Action
    changePassword(changePassword: ChangePassword): Promise<DefaultResult> {
        return UserService.changePassword(changePassword);
    }

    @Action({ commit: "storeUser" })
    retrieveUser(): Promise<User> {
        return UserService.getOwnUser();
    }

    @Action({ commit: "updateStateTimes" })
    updateUserStateTimes(): Promise<StateTime> {
        return UserService.updateTimes();
    }

    @Action
    logoutUser(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            try {
                this.destroyToken();
                resolve(true);
            } catch (error) {
                reject(error);
            }
        });
    }

    @Mutation
    storeToken(token: string) {
        localStorage.setItem(localTokenName, token); // in localstorage
        this.token = token;
    }

    @Mutation
    destroyToken() {
        localStorage.removeItem(localTokenName);
        this.token = null;
    }

    @Mutation
    storeUser(user: User) {
        this.user = user;
    }

    @Mutation
    updateStateTimes(stateTimes: StateTime) {
        this.user.stateTimes = stateTimes;
    }

    @Mutation
    updateSettings(settings: UserSettings) {
        this.user.settings = settings;
    }
}

export default getModule(UserModule);
