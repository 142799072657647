import VueI18n from "vue-i18n";
import { EnumErrorCode } from "timepowerweb-common/error";
import { i18n } from "../plugins/i18n";


type TextType = "text" | "headline";

/**
 * Return an i18n result for a specific error code.
 * If no special text is found the default error will be return.
 *
 * @param errorCode - The error code.
 * @param type The type of text.
 * @returns
 *  The i18n result.
 */
export function getSpecialLangTextForErrorCode(errorCode: EnumErrorCode, type: TextType = "text"): VueI18n.TranslateResult {
    const enumValue = [EnumErrorCode[errorCode]];
    const key = `site.error.${errorCode}.${type}`;
    const value = i18n.t(key, enumValue);
    return value === key ? i18n.t(`site.error.default.${type}`, enumValue) : value;
}
