var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',[_c('v-form',{ref:"form",staticClass:"text-left",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card-title',[_c('v-icon',[_vm._v("mdi-account-lock-outline")]),_vm._v("   "+_vm._s(_vm.$t("site.component.settings.changePassword"))+" ")],1),_c('v-card-subtitle',[(_vm.subtitle)?_c('span',[_vm._v(" "+_vm._s(_vm.subtitle)+" ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.$t("site.component.password.changePasswortSubtitle", [ _vm.appName, ]))+" ")]),_c('v-card-text',[(!_vm.password)?_c('passwordFieldComponent',{ref:"oldPwd",attrs:{"label":_vm.passwordOldLabel},on:{"keyDownCapsLock":_vm.keyDownCapsLock},model:{value:(_vm.passwordOld),callback:function ($$v) {_vm.passwordOld=$$v},expression:"passwordOld"}}):_vm._e(),_c('passwordFieldComponent',{ref:"newPwd",attrs:{"label":_vm.passwordNewLabel,"rules":[
                    ..._vm.passwordRuleValidation,
                    ..._vm.passwordCnxDeRuleValidation,
                ]},on:{"keyDownCapsLock":_vm.keyDownCapsLock},model:{value:(_vm.passwordNew),callback:function ($$v) {_vm.passwordNew=$$v},expression:"passwordNew"}}),_c('passwordFieldComponent',{ref:"newConfirmPwd",attrs:{"label":_vm.passwordNewConfirmLabel,"rules":[
                    ..._vm.passwordRuleValidation,
                    ..._vm.passwordConfirmValidation,
                ],"keydownenter":_vm.submit},on:{"keyDownCapsLock":_vm.keyDownCapsLock},model:{value:(_vm.passwordNewConfirm),callback:function ($$v) {_vm.passwordNewConfirm=$$v},expression:"passwordNewConfirm"}})],1),_c('v-card-actions',[(_vm.isCapsLockActive)?_c('v-card-text',{staticClass:"py-0 error--text font-weight-bold text-left"},[_vm._v(" "+_vm._s(_vm.$t("site.component.password.capsLockOnWarning"))+" ")]):_vm._e(),_c('v-spacer'),_c('v-btn',{class:{
                    primary: _vm.valid,
                },attrs:{"disabled":!_vm.valid,"color":"secondary"},on:{"click":_vm.submit}},[_vm._v(_vm._s(_vm.$t("site.component.password.changePassword"))+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }