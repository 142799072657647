<template>
    <v-card class="px-3 fill-height">
        <v-tabs class="pa-0" v-model="tab">
            <v-tab>{{ $t("site.views.timetracking.history") }}</v-tab>
            <v-tab>{{ $t("site.views.timetracking.timpowerEntryList") }}</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab" class="pt-1">
            <v-tab-item>
                <tableComponent
                    :isSingleSelect="true"
                    :showSelect="true"
                    :items="favoritesList"
                    :headers="favoritesTableHeaders"
                    keyValue="lastUse"
                    v-model="favoritesSelection"
                    :hasAtLeastOnSelected="true"
                />
            </v-tab-item>
            <v-tab-item>
                <timepowerEntryViewComponent />
            </v-tab-item>
        </v-tabs-items>
    </v-card>
</template>

<script lang="ts">
import Vue from "vue";
import TableComponent from "./table-component.vue";
import TimepowerEntryViewComponent from "./timepowerentryview-component.vue";
import CompanyModule from "../store/modules/company-module";
import { WorkType } from "timepowerweb-common/model/worktype-model";
import EventModule from "../store/modules/event-module";
import { plainToInstance } from "class-transformer";
import { EventTimepower, EnumEventType } from "timepowerweb-common/model/event-model";
import { InternalOrder } from "timepowerweb-common/model/internalorder-model";
import InternalOrderService from "../services/internalorder-service";
import { getInternalOrderFromFavorite, getWorkTypeFromFavorite, InternalOrderFavoritesList } from "timepowerweb-common/model/internalorderfavorites-model";
import moment from "moment";
import { i18n } from "../plugins/i18n";

export default Vue.extend({
    name: "InternalOrderComponent",
    data: () => ({
        tab: 0,
        favoritesList: [] as InternalOrderFavoritesList,
        favoritesSelection: [] as InternalOrderFavoritesList,
    }),
    computed: {
        company: () => CompanyModule.company,
        favoritesTableHeaders: () => [
            {
                text: i18n.t("object.event.costCenter"),
                value: "costCenterDescription",
            },
            {
                text: i18n.t("object.event.internalOrder"),
                value: "internalOrderDescription",
            },
            {
                text: i18n.t("object.event.workType"),
                value: "workTypeDescription",
            },
            {
                text: i18n.t("object.event.lastUsed"),
                value: "lastUse",
                formatter: (x: Date) => (x ? moment(x).format("L LTS") : null),
            },
        ],
    },
    components: {
        tableComponent: TableComponent,
        timepowerEntryViewComponent: TimepowerEntryViewComponent,
    },
    watch: {
        company: {
            handler(newValue, oldValue) {
                if (newValue !== oldValue) {
                    if (newValue.id == "") {
                        this.favoritesList.length = 0;
                    } else {
                        return InternalOrderService.getFavoritesList(newValue)
                            .then((list) => {
                                this.favoritesList = list;
                                if (this.favoritesList.length == 0) {
                                    this.tab = 1;
                                }
                                return;
                            });
                    }
                }
            },
            immediate: true,
        },
        favoritesSelection: {
            handler(newValue: InternalOrderFavoritesList) {
                const favorite = newValue[0];
                if (favorite !== undefined) {
                    executeStatusChange(getInternalOrderFromFavorite(favorite), getWorkTypeFromFavorite(favorite));
                }
            },
            immediate: true,
        },
    },
});

export function executeStatusChange(internalOrder: InternalOrder, workType: WorkType): void {
    if (internalOrder.id !== "" && workType.id !== "") {
        EventModule.storeNextEvent(plainToInstance(EventTimepower, {
            internalOrder: internalOrder,
            workType: workType,
            isInHomeOffice: EventModule.nextEvent.isInHomeOffice,
            eventType: EventModule.nextEvent.eventType == EnumEventType.TrackingChanged ? EventModule.nextEvent.eventType : EnumEventType.TrackingActive,
        }));
    }
}
</script>

<style>
.v-window {
    height: 91%;
}
.v-window__container,
.v-window-item {
    height: 100% !important;
}
</style>
