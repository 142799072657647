import VueRouter, { NavigationGuardNext, Route } from "vue-router";
import Vue from "vue";
import LoginView from "../views/login-view.vue";
import ReportingView from "../views/reporting-view.vue";
import LogoutDialogView from "../views/logoutdialog-view.vue";
import AboutView from "../views/about-view.vue";
import MainComponent from "../components/main-component.vue";
import NavigationComponent from "../components/navigation-component.vue";
import AppBarComponent from "../components/appbar-component.vue";
import SettingsView from "../views/settings-view.vue";
import ErrorView from "../views/error-view.vue";
import TokenView from "../views/token-view.vue";

import TimeTrackingView from "../views/timetracking-view.vue";
import { defaultLanguage, getLanguage, getLocaleToUrlLocale, getUrlLocaleToLocale, i18n, loadLanguageAsync, storageKeyLang } from "./i18n";
import userModule from "../store/modules/user-module";
import { catClient, catHelper } from "../helper/logging";
import { appName } from "timepowerweb-common/config";

//change page title for each route.
//titles are configures in routes.js
//otherwise DEFAULT_TITLE will be set
const DEFAULT_TITLE = appName;
const titlePrefix = DEFAULT_TITLE + " - ";

export const ROUTE_HOME = "home";
export const ROUTE_LOGIN = "login";
export const ROUTE_LOGOUT = "logout";

export const ROUTE_TIMETRACKING = "timetracking";
export const ROUTE_REPORTING = "reporting";
export const ROUTE_SETTINGS = "settings";
export const ROUTE_ABOUT = "about";
export const ROUTE_ERROR = "error";
export const ROUTE_TOKEN = "token";

export const ROUTE_DEFAULT = ROUTE_TIMETRACKING;

Vue.use(VueRouter);

export const router = new VueRouter({
    routes: [
        {
            path: `/${ROUTE_TOKEN}/:token`,
            component: TokenView,
            meta: {
                useNoLocaleCheck: true,
            },
        },
        {
            path: `/${ROUTE_ERROR}/:error`,
            meta: {
                redirectDefaultLocale: true,
            },
        },
        {
            path: "/:locale",
            components: {
                default: MainComponent,
                navigation: NavigationComponent,
                appbar: AppBarComponent,
            },
            children: [
                {
                    path: "", // Needs to be empty.
                    name: ROUTE_HOME,
                    redirect: {
                        name: ROUTE_DEFAULT,
                    },
                },
                {
                    path: ROUTE_LOGIN,
                    name: ROUTE_LOGIN,
                    component: LoginView,
                    meta: {
                        doLogIn: true,
                        title: () => i18n.t("site.login"),
                        nav: {
                            icon: "mdi-login-variant",
                        },
                    },
                },
                {
                    path: ROUTE_TIMETRACKING,
                    name: ROUTE_TIMETRACKING,
                    component: TimeTrackingView,
                    meta: {
                        requiresUserLogin: true,
                        title: () => i18n.t("site.timetracking"),
                        nav: {
                            icon: "mdi-file-tree-outline",
                        },
                    },
                },
                {
                    path: ROUTE_REPORTING,
                    name: ROUTE_REPORTING,
                    component: ReportingView,
                    meta: {
                        requiresUserLogin: true,
                        title: () => i18n.t("site.reporting"),
                        nav: {
                            icon: "mdi-chart-bar",
                        },
                    },
                },
                {
                    path: ROUTE_SETTINGS,
                    name: ROUTE_SETTINGS,
                    component: SettingsView,
                    meta: {
                        requiresUserLogin: true,
                        title: () => i18n.t("site.settings"),
                        nav: {
                            icon: "mdi-cog-outline",
                        },
                    },
                },
                {
                    path: ROUTE_ABOUT,
                    name: ROUTE_ABOUT,
                    component: AboutView,
                    meta: {
                        requiresUserLogin: true,
                        title: () => i18n.t("site.about"),
                        nav: {
                            icon: "mdi-information-outline",
                        },
                    },
                },
                {
                    path: ROUTE_LOGOUT,
                    name: ROUTE_LOGOUT,
                    component: LogoutDialogView,
                    meta: {
                        requiresUserLogin: true,
                        title: () => i18n.t("site.logout"),
                        nav: {
                            icon: "mdi-logout-variant",
                        },
                    },
                },
                {
                    path: `${ROUTE_ERROR}/:error`,
                    name: ROUTE_ERROR,
                    component: ErrorView,
                    meta: {
                        requiresUserLogin: false,
                        title: () => i18n.t("site.error.title"),
                    },
                },
                {
                    path: "*",
                },
            ],
        },
    ],
    mode: "history",
});

router.beforeEach((to, _from, next) => {
    const localeFromRoute = to.params.locale ?? getLocaleToUrlLocale(getLanguage());

    const locale = getUrlLocaleToLocale(localeFromRoute) ?? defaultLanguage;

    localStorage.setItem(storageKeyLang, locale);
    if (i18n.locale !== locale) {
        loadLanguageAsync(locale)
            .then(() => executeRouterBeforeEach(locale, to, localeFromRoute, next))
            .catch((error) => catClient.error("Error before execute a route", error));
    } else {
        executeRouterBeforeEach(locale, to, localeFromRoute, next);
    }

});

router.afterEach((to) => {
    // Use next tick to handle router history correctly
    // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
    Vue.nextTick(() => {
        // if title is not set use title from nav.title / DEFAULT_TITLE
        document.title = to.meta?.title ? titlePrefix + (to.meta.title instanceof Function ? to.meta.title() : to.meta.title) : DEFAULT_TITLE;
    });
});

/**
 *
 * @param locale
 * @param to
 * @param localeFromRoute
 * @param next
 */
function executeRouterBeforeEach(locale: string, to: Route, localeFromRoute: string, next: NavigationGuardNext<Vue>): void {
    // except token route from Locale check
    if (to.meta?.redirectDefaultLocale) {
        next({ path: localeFromRoute + to.path, params: Object.assign({}, to.params, { locale: localeFromRoute }) });
    }
    else if (!to.meta?.useNoLocaleCheck
        && getLocaleToUrlLocale(locale) !== to.params.locale) {
        if (to.params.locale !== undefined) {
            Vue.$toast.error(i18n.t("site.general.languageNotAvailable", [localeFromRoute]));
        }
        next({ name: to.name ?? ROUTE_DEFAULT, params: { locale: getLocaleToUrlLocale(locale) } });
    }
    else if (to.matched.some((record) => record.meta.doLogIn)) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        if (userModule.isLoggedIn) {
            next({
                name: ROUTE_DEFAULT,
                params: to.params,
            });
        }
    } else if (to.matched.some((record) => record.meta.requiresUserLogin)) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        if (!userModule.isLoggedIn) {
            next({
                name: ROUTE_LOGIN,
                params: to.params,
            });
        }
    } else {
        // Check if route to match to nothing
        // or if it match it has no redirect and no components definition
        // (because of locale parent, parents (first level) must be ignored)
        if (to.matched.length == 0
            || to.matched.some((x) => x.parent !== undefined
                && x.components.default === undefined
                && x.redirect === undefined)) {
            catHelper.error(`Route ${to.path} not found?!`, null);
            Vue.$toast.error(i18n.t("site.general.pageNotAvailable", [to.params.pathMatch ?? to.path]));
            next({ name: ROUTE_DEFAULT });
        }
    }

    next();
}

/**
 *
 */
export function isDefaultLanguageInRoute(): boolean {
    return router.currentRoute.params.locale == getLocaleToUrlLocale(defaultLanguage);
}
