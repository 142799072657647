<template>
    <v-navigation-drawer app v-model="isNavigationBarVisible" width="350">
        <v-card class="d-flex fill-height flex-column">
            <v-card class="fill-height" flat tile>
                <v-card flat tile :color="backgroundColor">
                    <v-img height="230" :src="'/images/' + backgroundImage">
                        <v-list>
                            <v-list-item class="pa-4">
                                <v-sheet
                                    color="white"
                                    elevation="10"
                                    rounded="lg"
                                    height="64"
                                    width="64"
                                    class="pa-1"
                                >
                                    <v-img
                                        alt="Application Icon"
                                        src="/images/applicationLogo.png"
                                        height="56"
                                        width="56"
                                    />
                                </v-sheet>

                                <v-spacer></v-spacer>

                                <v-btn
                                    @click="toggleLanguage"
                                    fab
                                    small
                                    color="secondary"
                                    :aria-label="
                                        $t('site.general.languageChange')
                                    "
                                >
                                    <v-icon>mdi-translate</v-icon>
                                </v-btn>
                                <v-menu
                                    offset-y
                                    nudge-left="140"
                                    nudge-bottom="10"
                                    rounded="xl"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            class="ma-3"
                                            fab
                                            small
                                            color="secondary"
                                            v-bind="attrs"
                                            v-on="on"
                                            :aria-label="
                                                $t(
                                                    'site.general.changeMainColor'
                                                )
                                            "
                                        >
                                            <v-icon>mdi-palette</v-icon>
                                        </v-btn>
                                    </template>
                                    <v-card>
                                        <v-container>
                                            <v-row
                                                v-for="(
                                                    colorRow, rowIndex
                                                ) in colorGrid"
                                                :key="rowIndex"
                                            >
                                                <v-col
                                                    cols="auto"
                                                    v-for="(
                                                        colorEntry, colIndex
                                                    ) in colorRow"
                                                    :key="colIndex"
                                                >
                                                    <v-btn
                                                        fab
                                                        :color="colorEntry.base"
                                                        @click="
                                                            selectedThemeColor =
                                                                colorEntry
                                                        "
                                                    >
                                                        <v-icon
                                                            color="white"
                                                            large
                                                            v-if="
                                                                selectedThemeColor ==
                                                                colorEntry
                                                            "
                                                            >mdi-check</v-icon
                                                        >
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-card>
                                </v-menu>
                                <v-btn
                                    @click="toggleDarkmode"
                                    fab
                                    small
                                    :dark="!darkModeActive"
                                    :light="darkModeActive"
                                    :aria-label="$t('site.general.changeTheme')"
                                >
                                    <v-icon>mdi-theme-light-dark</v-icon>
                                </v-btn>
                            </v-list-item>

                            <v-list-item
                                role="complementary"
                                :aria-label="
                                    $t('site.component.navigation.userInfo')
                                "
                            >
                                <userInfoComponent />
                            </v-list-item>
                        </v-list>
                    </v-img>
                </v-card>
                <v-divider />
                <v-list nav dense>
                    <v-list-item
                        v-for="route in routes"
                        :key="route.path"
                        :to="{
                            name: route.name,
                            params: { locale: locateUrl },
                        }"
                        color="primary"
                    >
                        <v-list-item-icon>
                            <v-icon>{{ route.meta.nav.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>
                            {{ route.meta.title() }}
                        </v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-card>

            <v-card align-end flat tile v-if="loggedIn">
                <v-divider />
                <stateTimeComponent
                    role="complementary"
                    :aria-label="$t('site.component.navigation.stateTimeInfo')"
                />
            </v-card>
        </v-card>
    </v-navigation-drawer>
</template>

<script lang="ts">
import Vue from "vue";
import StateTimeComponent from "./statetime-component.vue";
import UserModule from "../store/modules/user-module";
import UserInfoComponent from "./userinfo-component.vue";
import { RouteRecordPublic } from "vue-router";
import { getLocaleToUrlLocale, toggleBetweenLanguages } from "../plugins/i18n";
import { colorMenuTileBackground, storageKeyTheme, colorList } from "../plugins/vuetify";

export default Vue.extend({
    name: "NavigationComponent",
    components: {
        stateTimeComponent: StateTimeComponent,
        userInfoComponent: UserInfoComponent,
    },
    computed: {
        isNavigationBarVisible: {
            get() {
                return this.$store.state.isNavigationBarVisible;
            },
            set(val) {
                this.$store.commit("setNavigationBarVisible", val);
            },
        },
        loggedIn() {
            return UserModule.isLoggedIn;
        },
        routes() {
            return this.$router.getRoutes().filter((x) => x.meta.nav !== undefined && calculateNavVisible(x));
        },
        darkModeActive() {
            return this.$vuetify.theme.dark;
        },
        backgroundImage() {
            return this.$vuetify.theme.dark ? "applicationLogoBackgroundBlack.png" : "applicationLogoBackgroundWhite.png";
        },
        backgroundColor() {
            return colorMenuTileBackground;
        },
        locateUrl() {
            return getLocaleToUrlLocale(this.$i18n.locale);
        },
        colorGrid() {
            var gridOfColors = chunkArray(colorList, 4);
            return gridOfColors;
        },
        selectedThemeColor: {
            get() {
                return this.$store.state.selectedThemeColor;
            },
            set(newColor) {
                this.$store.commit("setSelectedThemeColor", newColor);
            },
        },
    },
    methods: {
        toggleDarkmode() {
            this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
            localStorage.setItem(storageKeyTheme, this.$vuetify.theme.dark.toString());
        },
        toggleLanguage() {
            const locale = getLocaleToUrlLocale(toggleBetweenLanguages());
            const to = this.$router.resolve({ params: { locale } });
            this.$router.push(to.location);
        },
    },
});

/**
 *
 * @param arr
 * @param size
 */
function chunkArray<T>(arr: T[], size: number): T[][] {
    return arr.length > size
        ? [arr.slice(0, size), ...chunkArray(arr.slice(size), size)]
        : [arr];
}

function calculateNavVisible(route: RouteRecordPublic): boolean {
    if (route.meta?.doLogIn !== undefined) {
        return !route.meta.doLogIn || !UserModule.isLoggedIn;
    }
    else if (route.meta?.requiresUserLogin !== undefined) {
        return !route.meta.requiresUserLogin || UserModule.isLoggedIn;
    }
    return false;
}

</script>
