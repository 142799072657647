<template>
    <v-form v-model="valid" ref="form">
        <v-text-field
            :label="loginNameLabel()"
            v-model="loginName"
            :rules="loginNameRules()"
            required
            prepend-icon="mdi-account"
        />
        <passwordFieldComponent
            :label="passwordLabel()"
            v-model="password"
            :rules="passwordRules()"
            :keydownenter="submit"
            @keyDownCapsLock="keyDownCapsLock"
        />

        <v-card-actions>
            <v-card-text
                v-if="isCapsLockActive"
                class="py-0 text-left error--text font-weight-bold"
            >
                {{ $t("site.component.password.capsLockOnWarning") }}
            </v-card-text>
            <v-spacer />
            <v-btn @click="submit" :disabled="!valid" color="secondary"
                >{{ $t("site.login") }}
            </v-btn>
        </v-card-actions>
        <v-card-text class="red--text" v-show="!!errorText">
            {{ errorText }}
        </v-card-text>
        <v-dialog v-model="dialog" persistent width="400">
            <passwordChangeComponent
                @submit="passwordChangeSuccess"
                :token="token"
                :password="password"
                :subtitle="subtitle"
            />
        </v-dialog>
    </v-form>
</template>

<script lang="ts">

import Vue from "vue";
import { i18n } from "../plugins/i18n";
import UserModule from "../store/modules/user-module";
import PasswordFieldComponent from "../components/passwordfield-component.vue";
import PasswordChangeComponent from "../components/passwordchange-component.vue";
import { EnumErrorCode } from "timepowerweb-common/error";
import { getSpecialLangTextForErrorCode } from "../helper/error";

export default Vue.extend({
    name: "LoginFormComponent",
    components: {
        passwordFieldComponent: PasswordFieldComponent,
        passwordChangeComponent: PasswordChangeComponent,
    },
    data: () => ({
        dialog: false,
        valid: false,
        password: "",
        passwordRules: () => [
            (v: string) => !!v || i18n.t("site.general.passwordRequired"),
        ],
        errorText: "",
        passwordLabel: () => i18n.t("site.component.login.passwordLabel"),
        loginNameLabel: () => i18n.t("site.component.login.loginLabel"),
        loginName: "",
        loginNameRules: () => [
            (v: string) => !!v || i18n.t("site.component.login.loginRequired"),
            (v: string) => /^[0-9]*$/.test(v) || i18n.t("site.component.login.loginOnlyNumbers"),
        ],
        token: "",
        subtitle: "",
        isCapsLockActive: false,
    }),
    computed: {
        locale() {
            return this.$i18n.locale;
        },
    },
    methods: {
        keyDownCapsLock(data: boolean) {
            this.isCapsLockActive = data;
        },
        submit() {
            this.errorText = "";
            UserModule.loginUser({
                loginName: this.loginName.padStart(8, "0"),
                password: this.password,
            })
                .then((response) => {
                    this.token = response.token;
                    if (response.requireNewPassword) {
                        this.dialog = true;
                        const text = response.isInvalid ? i18n.t("site.component.login.passwordInvalidInfo") : i18n.t("site.component.login.passwordResetInfo");
                        this.subtitle = i18n.t("site.component.login.passwordInfo", [text]).toString();
                    } else {
                        UserModule.storeToken(this.token);
                        this.finalLogin();
                    }
                    return;
                })
                .catch((err) => {
                    if (err.errorCode && err.errorCode() === EnumErrorCode.LOGIN_METHOD_NOT_AVAILABLE) {
                        this.errorText = i18n.t("site.component.login.wrongAuthMethod").toString();
                    } else if (err.errorCode && err.errorCode() !== undefined) {
                        this.errorText = getSpecialLangTextForErrorCode(err.errorCode()).toString();
                    } else {
                        this.errorText = i18n.t("site.component.login.loginError").toString();
                    }
                    this.$toast.error(this.errorText);
                    this.$emit("error");
                });
        },
        finalLogin() {
            this.token = "";
            this.$emit("submit");
        },
        passwordChangeSuccess() {
            this.dialog = false;
            this.subtitle = "";
            this.finalLogin();
        },
    },
    watch: {
        locale: {
            handler(newValue, oldValue) {
                if (newValue !== oldValue
                    && oldValue !== undefined) {
                    (this.$refs.form as Element & { validate(): void, }).validate();
                }
            },
            immediate: true,
        },
    }
});
</script>
