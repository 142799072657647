import store from "..";
import { Module, VuexModule, Mutation, getModule } from "vuex-module-decorators";
import { Company } from "timepowerweb-common/model/company-model";
import { registerPepTask, unregisterPepTask } from "../../serviceWorker/pepwarning-task";
import UserModule from "./user-module";


/**
 *
 */
@Module({
    name: "company",
    store,
    namespaced: true,
    dynamic: true,
})
class CompanyModule extends VuexModule {
    company: Company = new Company();

    @Mutation
    storeCompany(company?: Company) {
        if (company) {
            if (!UserModule.user.settings.blockPepWarnings) {
                registerPepTask();
            }
        }
        else {
            unregisterPepTask();
        }
        this.company = company ?? new Company();
    }
}

export default getModule(CompanyModule);
