<template>
    <span>
        {{ timestamp }}
    </span>
</template>

<script lang="ts">
import moment from "moment";
import Vue from "vue";

export default Vue.extend({
    name: "SysdateComponent",
    data: () => ({
        timestamp: "",
    }),
    created() {
        setInterval(this.getNow, 1000);
    },
    methods: {
        getNow: function () {
            this.timestamp = moment().format("L LTS");
        },
    },
});
</script>
