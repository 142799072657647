<template>
    <v-card flat class="fill-height" color="transparent">
        <v-progress-linear indeterminate v-if="loading" />
        <v-container class="pa-0 fill-height" block fluid>
            <v-row class="fill-height" v-if="!loading">
                <v-col cols="6" sm="7" lg="8" xl="9" class="pa-0">
                    <internalOrderComponent
                        v-if="!isInternalOrderForNextEventChangeable"
                    />
                    <statusControlComponent
                        v-if="isInternalOrderForNextEventChangeable"
                    />
                </v-col>
                <v-col class="pa-0 ps-3">
                    <statusInfoComponent class="fill-height" />
                </v-col>
            </v-row>
        </v-container>
    </v-card>
</template>

<script lang="ts">
import Vue from "vue";

import InternalOrderComponent from "../components/internalorder-component.vue";
import StatusInfoComponent from "../components/statusinfo-component.vue";
import EventModule from "../store/modules/event-module";
import StatusControlComponent from "../components/statuscontrol-component.vue";
import UserModule from "../store/modules/user-module";
import { User } from "timepowerweb-common/model/user-model";
import { catClient } from "../helper/logging";


export default Vue.extend({
    name: "TimeTrackingView",
    data: () => ({
        loading: true,
    }),
    components: {
        internalOrderComponent: InternalOrderComponent,
        statusInfoComponent: StatusInfoComponent,
        statusControlComponent: StatusControlComponent,
    },
    computed: {
        isInternalOrderForNextEventChangeable: () => EventModule.isInternalOrderForNextEventChangeable,
        user: () => UserModule.user,
    },
    watch: {
        user: {
            handler(newValue: User, oldValue: User) {
                // User will be updated by statetime refresh, password change etc.
                // Only retrieve current event if user switches from being logged off to logged in
                if (newValue?.id !== "-1"
                    && (oldValue === undefined || oldValue.id === "-1")) {
                    EventModule.retrieveCurrentEvent()
                        .catch((error) => catClient.error("userHandlerCurrentEvent", error))
                        .finally(() => this.loading = false);
                }
            },
            immediate: true,
        },
    },
});
</script>
