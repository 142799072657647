<template>
    <v-text-field
        ref="field"
        :label="label"
        v-model="content"
        :append-icon="isPwdVisible ? 'mdi-eye' : 'mdi-eye-off'"
        @click:append="() => (isPwdVisible = !isPwdVisible)"
        :type="isPwdVisible ? 'text' : 'password'"
        :rules="rules"
        counter
        required
        @input="handleInput"
        @keydown.enter="handleEnter"
        @keydown="handleKey"
        prepend-icon="mdi-lock"
    />
</template>
<script lang="ts">
import Vue from "vue";

export type PasswordField = Vue & { reset: () => void, };
export type TextField = Vue & { resetValidation: () => void, };
export default Vue.extend({
    name: "PasswordFieldComponent",
    props: [
        "label",
        "rules",
        "value",
        "keydownenter",
        "icon",
    ],
    data() {
        return {
            content: this.value,
            valid: false,
            isPwdVisible: false,
        };
    },
    methods: {
        handleInput() {
            this.$emit("input", this.content);
        },
        handleKey(e: KeyboardEvent) {
            this.$emit("keyDownCapsLock", e.getModifierState("CapsLock"));
        },
        handleEnter() {
            if (this.keydownenter !== undefined) {
                this.keydownenter();
            }
        },
        reset() {
            this.content = "";
            this.handleInput();
            this.isPwdVisible = false;
            (this.$refs.field as TextField)?.resetValidation();
        },
    },
});
</script>
