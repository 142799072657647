<template>
    <v-card>
        <v-card-title>
            {{ $t("site.views.error.headline", [errorHeaderLine]) }}
        </v-card-title>
        <v-card-text class="text-left">
            {{ errorText }}
        </v-card-text>
    </v-card>
</template>

<script lang="ts">
import Vue from "vue";
import { EnumErrorCode } from "timepowerweb-common/error";
import { getSpecialLangTextForErrorCode } from "../helper/error";

export default Vue.extend({
    name: "ErrorView",
    computed: {
        errorHeaderLine() {
            return getSpecialLangTextForErrorCode(this.$route.params.error as unknown as EnumErrorCode, "headline");
        },
        errorText() {
            return getSpecialLangTextForErrorCode(this.$route.params.error as unknown as EnumErrorCode, "text");
        }
    },
    methods: {

    }
});

</script>
