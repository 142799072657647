<template>
    <div>
        <v-card
            v-for="login in logins"
            @click="login.click()"
            :key="login.type"
            class="pa-3 my-2"
            :max-width="maxWidth"
        >
            <v-row class="align-center">
                <v-col>
                    <v-img v-if="login.image" :src="login.image" />
                    <v-card-title>
                        <v-icon v-if="login.icon">{{ login.icon }}</v-icon>
                        &nbsp; {{ login.textTitle() }}
                    </v-card-title>
                    <v-card-text align="left">
                        {{ login.textAdditionalInfo() }}
                    </v-card-text>
                </v-col>
                <v-col cols="auto">
                    <v-card-actions>
                        <v-icon>mdi-chevron-right</v-icon>
                    </v-card-actions>
                </v-col>
            </v-row>
        </v-card>

        <v-card
            :max-width="maxWidth"
            class="pa-3 my-2 text-center"
            v-if="internalLoginAvailable"
        >
            <v-card-title v-if="logins.length > 0" class="justify-center">
                {{ $t("site.views.login.orDividerText") }}
            </v-card-title>
            <v-card-title>
                <v-icon>mdi-account-key-outline</v-icon>
                &nbsp; {{ $t("site.views.login.buttonTitleClassicLogin") }}
            </v-card-title>
            <v-card-text>
                <loginFormComponent @submit="submitSuccess" />
            </v-card-text>
        </v-card>

        <v-card :max-width="maxWidth" class="pa-3" v-if="error">
            <v-card-title>{{
                $t("site.general.errorMessageTitle")
            }}</v-card-title>
            <v-card-text>
                {{ $t("site.views.login.errorMessage") }}
            </v-card-text>
        </v-card>
    </div>
</template>

<script lang="ts">
import Vue from "vue";
import { LoginMethodType } from "timepowerweb-common/model/serverinfo-model";
import LoginFormComponent from "../components/loginform-component.vue";
import { i18n } from "../plugins/i18n";
import { ROUTE_DEFAULT } from "../plugins/router";
import ServerInfoModule from "../store/modules/serverinfo-module";

export default Vue.extend({
    name: "LoginView",
    components: { loginFormComponent: LoginFormComponent },
    data: () => ({
        maxWidth: 500,
        loading: false,
        error: false,
    }),
    computed: {
        logins() {
            return ServerInfoModule.serverInfo.loginMethods.filter((x) => x.type !== LoginMethodType.Intern && x.url !== undefined)
                .map((x) => {
                    switch (x.type) {
                        case LoginMethodType.SamlAdfs: {
                            return {
                                type: LoginMethodType.SamlAdfs,
                                textTitle: () => i18n.t("site.views.login.buttonTitleAdfs"),
                                textAdditionalInfo: () => i18n.t("site.views.login.buttonAdditionalInfoAdfs"),
                                click: () => location.replace(x.url ?? ""),
                                icon: "mdi-cloud-lock-outline"
                            };
                        }
                    }
                });
        },
        internalLoginAvailable() {
            return !this.loading && ServerInfoModule.serverInfo.loginMethods.find((x) => x.type == LoginMethodType.Intern);
        }
    },
    methods: {
        submitSuccess() {
            this.$router.push({
                name: ROUTE_DEFAULT,
            },
            );
        },
    },
});
</script>
