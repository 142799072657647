import store from "..";
import { Module, VuexModule, Mutation, Action, getModule } from "vuex-module-decorators";
import { checkIfNextEventDerivedFromCurrent, EnumEventType, EventTimepower } from "timepowerweb-common/model/event-model";
import EventService from "../../services/event-service";
import Vue from "vue";
import { i18n } from "../../plugins/i18n";
import UserModule from "./user-module";
import { catClient } from "../../helper/logging";

/**
 *
 */
@Module({
    name: "event",
    store,
    namespaced: true,
    dynamic: true,
})
class EventModule extends VuexModule {
    nextEvent = new EventTimepower();
    currentEvent = new EventTimepower();
    isSaveEventAllowed = true;

    get isInternalOrderForNextEventChangeable() {
        return !(this.currentEvent.eventType === EnumEventType.TrackingStopped
            || this.nextEvent.eventType === EnumEventType.TrackingChanged);
    }

    @Action
    saveNextEvent(): Promise<boolean> {
        if (this.isSaveEventAllowed) {
            this.toggleSaveEventAllowed();

            return EventService.retrieveCurrentEvent()
                .then((currentEvent) => {
                    if (checkIfNextEventDerivedFromCurrent(this.currentEvent, currentEvent)) {
                        return EventService.postNextEvent(this.nextEvent);
                    }

                    Vue.$toast.warning(i18n.t("store.module.event.saveCurrentEventDiffer"));
                    this.updateCurrentEvent(currentEvent);
                    throw false;
                })
                .then((event) => {
                    this.updateCurrentEvent(event);
                    if (event.isInHomeOffice !== this.currentEvent.isInHomeOffice) {
                        return UserModule.retrieveUserSettings();
                    } else {
                        return UserModule.user.settings;
                    }
                })
                .then(() => true)
                .catch((error) => {
                    catClient.error("saveNextEvent", error);
                    return false;
                })
                .finally(() => this.toggleSaveEventAllowed());
        }

        return Promise.resolve(false);
    }

    @Action
    retrieveCurrentEvent(): Promise<EventTimepower> {
        return EventService.retrieveCurrentEvent()
            .then((event) => this.updateCurrentEvent(event));
    }

    @Action({ commit: "storeCurrentEvent" })
    updateCurrentEvent(event: EventTimepower): EventTimepower {
        // Event id different or home office status different if event id is undefined.
        if (event.id !== this.currentEvent.id
            || (!this.currentEvent.id && event.isInHomeOffice !== this.currentEvent.isInHomeOffice)) {
            this.storeNextEvent(Object.assign({}, event));
        }
        return event;
    }

    @Action
    cleanUp() {
        this.updateCurrentEvent(new EventTimepower());
        if (!this.isSaveEventAllowed) {
            this.toggleSaveEventAllowed();
        }
    }

    @Mutation
    toggleSaveEventAllowed() {
        this.isSaveEventAllowed = !this.isSaveEventAllowed;
    }

    @Mutation
    storeNextEvent(event?: EventTimepower) {
        event = event ?? this.currentEvent;
        if (event.id === undefined) {
            event.id = this.currentEvent.id;
        }
        this.nextEvent = event;
    }

    @Mutation
    storeCurrentEvent(event?: EventTimepower) {
        event = event ?? this.currentEvent;
        this.currentEvent = event;
    }

    @Mutation
    updateEventTypeForNextEvent(type: EnumEventType) {
        this.nextEvent.eventType = type;
    }
}

export default getModule(EventModule);
