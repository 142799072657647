<template>
    <div>
        <v-card
            class="mb-3"
            role="region"
            :aria-label="$t('site.component.reporting.reportSelectionSection')"
        >
            <v-card-title>
                {{ $t("site.views.reporting.labelReports") }}
            </v-card-title>
            <v-card-text>
                <v-select
                    outlined
                    small
                    :label="$t('site.views.reporting.labelReports')"
                    :items="reports"
                    class="pb-0"
                    full-width
                    hide-details
                    item-text="description"
                    item-value="id"
                    v-model="report"
                    return-object
                />
            </v-card-text>
            <v-card-actions>
                <div
                    class="ma-1"
                    v-for="item in reportParameter"
                    :key="item.type"
                >
                    <v-text-field
                        dense
                        outlined
                        :type="ifTextField(item.type) ? 'text' : 'number'"
                        :label="item.name"
                        v-if="!ifDateField(item.type)"
                        v-model="reportParameter"
                        :id="name"
                        hide-details
                    />
                    <v-menu
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        v-if="ifDateField(item.type)"
                        min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                :value="calculatedTime(item.value)"
                                :label="item.name"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                hide-details
                            />
                        </template>
                        <v-date-picker
                            v-model="item.value"
                            no-title
                            scrollable
                            :locale="$i18n.locale"
                        />
                    </v-menu>
                </div>
                <v-spacer />
                <v-btn
                    @click="executeReport"
                    class="mr-auto align-self-center"
                    color="secondary"
                >
                    {{ $t("site.general.execute") }}
                </v-btn>
            </v-card-actions>
        </v-card>
        <v-card
            role="region"
            :aria-label="$t('site.component.reporting.reportResultSection')"
        >
            <v-card-text>
                <tableComponent
                    :headers="headers"
                    :items="reportData.data"
                    :loading="loading"
                    :tableName="report.description"
                    :isExportAllowed="isExportAllowed"
                />
            </v-card-text>
        </v-card>
    </div>
</template>

<script lang="ts">
import moment from "moment";
import Vue from "vue";
import { Report, ReportList } from "timepowerweb-common/model/report-model";
import { ReportData } from "timepowerweb-common/model/reportdata-model";
import ReportService from "../services/report-service";
import TableComponent from "../components/table-component.vue";
import UserModule from "../store/modules/user-module";
import { catClient } from "../helper/logging";
import CompanyModule from "../store/modules/company-module";
import { EnumParameterType } from "timepowerweb-common/model/company-model";

type ReportParameter = {
    type: string,
    name: string,
    value: unknown,
};

type ReportParameterList = ReportParameter[];

export default Vue.extend({
    name: "ReportingComponent",
    props: ["runFirstReport"],
    data: () => ({
        reports: [] as ReportList,
        report: new Report(),
        reportData: new ReportData(),
        reportParameter: [] as ReportParameterList,
        loading: false,
    }),
    components: {
        tableComponent: TableComponent,
    },
    computed: {
        headers() {
            return this.reportData.header.map((x) => ({ text: x, value: x, sortable: true }));
        },
        locale() {
            return this.$i18n.locale;
        },
        isExportAllowed() {
            return CompanyModule.company.parameters[EnumParameterType.ExportXLSX]?.value === 1;
        }
    },
    methods: {
        executeReport() {
            this.loading = true;
            ReportService.getReportData(this.report, this.reportParameter.reduce((a, v) => ({ ...a, [v.type]: v.value }), {}))
                .then((reportData) => {
                    this.reportData = reportData;
                    return;
                })
                .catch((error) => {
                    this.reportData = new ReportData();
                    this.$toast.warning(this.$i18n.t("site.component.reporting.errorToastText"));
                    catClient.error("executeReport", error);
                })
                .finally(() =>
                    this.loading = false);

        },
        ifTextField(name: string) {
            return name.toUpperCase().includes("STRING");
        },
        ifDateField(name: string) {
            return name.toUpperCase().includes("DATE");
        },
        calculatedTime(value: string) {
            return moment(value).format("L");
        },
    },
    watch: {
        locale: {
            handler(newValue, oldValue) {
                if (newValue !== oldValue
                    && UserModule.isLoggedIn) {
                    ReportService.getReportList()
                        .then((reports) => {
                            this.reports = reports;
                            this.report = this.reports.find((x) => x.id === this.report.id) ?? this.reports[0];
                            this.reportData = new ReportData();
                            if (this.runFirstReport) {
                                this.executeReport();
                            }
                            return;
                        })
                        .catch((error) => catClient.error("localeHandler", error));
                }
            },
            immediate: true,
        },
        report: function (newValue: Report) {
            this.reportParameter = Object.entries(newValue?.parameter ?? []).map((pair) => ({
                type: pair[0],
                name: pair[1],
                value: undefined as unknown,
            }));
        },
        runFirstReport: {
            handler(newValue) {
                if (newValue
                    && this.reports.length > 0) {
                    if (this.report !== this.reports[0]) {
                        this.report = this.reports[0];
                    } else {
                        this.executeReport();
                    }
                }
            },
            immediate: true,
        },
    },
});
</script>
