<template>
    <v-card flat max-width="600" color="transparent">
        <v-card class="mb-3">
            <v-card-text>
                <a href="https://vuejs.org/">
                    <v-img
                        alt="Vue logo"
                        src="/images/vueLogo.png"
                        max-width="150"
                /></a>
                <h1>
                    {{ appName }} uses <a href="https://vuejs.org/">Vue.js</a> +
                    <a href="https://www.typescriptlang.org/">TypeScript</a>.
                </h1>
            </v-card-text>
        </v-card>

        <v-card class="mb-3">
            <v-card-text>
                <a href="https://vuetifyjs.com/">
                    <v-img
                        alt="Vuetify logo"
                        src="/images/vuetify-logo-dark-atom.svg"
                        max-width="150"
                    />
                </a>
                <h1>
                    {{ appName }} uses
                    <a href="https://vuetifyjs.com/">Vuetify</a>.
                </h1>
            </v-card-text>
        </v-card>

        <v-card class="mb-3">
            <v-card-text>
                <h2>
                    <v-sheet
                        color="white"
                        rounded="lg"
                        height="150"
                        width="150"
                        class="pa-1"
                    >
                        <v-img
                            alt="Application Icon"
                            src="/images/applicationLogo.png"
                            height="140"
                            width="140"
                        />
                    </v-sheet>

                    <a
                        href="https://thenounproject.com/term/time-tracking/3082653/"
                        >time tracking</a
                    >
                    by
                    <a href="https://thenounproject.com/support193/"
                        >myiconfinder</a
                    >
                    is licensed under
                    <a href="https://creativecommons.org/licenses/by/2.0/"
                        >CC BY 2.0</a
                    >
                </h2>
            </v-card-text>
        </v-card>
    </v-card>
</template>

<script lang="ts">
import Vue from "vue";
import { appName } from "timepowerweb-common/config";

export default Vue.extend({
    name: "AboutComponent",
    props: {
        msg: String,
    },
    data: () => ({
        appName: appName,
    }),
});
</script>
