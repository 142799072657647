var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-navigation-drawer',{attrs:{"app":"","width":"350"},model:{value:(_vm.isNavigationBarVisible),callback:function ($$v) {_vm.isNavigationBarVisible=$$v},expression:"isNavigationBarVisible"}},[_c('v-card',{staticClass:"d-flex fill-height flex-column"},[_c('v-card',{staticClass:"fill-height",attrs:{"flat":"","tile":""}},[_c('v-card',{attrs:{"flat":"","tile":"","color":_vm.backgroundColor}},[_c('v-img',{attrs:{"height":"230","src":'/images/' + _vm.backgroundImage}},[_c('v-list',[_c('v-list-item',{staticClass:"pa-4"},[_c('v-sheet',{staticClass:"pa-1",attrs:{"color":"white","elevation":"10","rounded":"lg","height":"64","width":"64"}},[_c('v-img',{attrs:{"alt":"Application Icon","src":"/images/applicationLogo.png","height":"56","width":"56"}})],1),_c('v-spacer'),_c('v-btn',{attrs:{"fab":"","small":"","color":"secondary","aria-label":_vm.$t('site.general.languageChange')},on:{"click":_vm.toggleLanguage}},[_c('v-icon',[_vm._v("mdi-translate")])],1),_c('v-menu',{attrs:{"offset-y":"","nudge-left":"140","nudge-bottom":"10","rounded":"xl"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-3",attrs:{"fab":"","small":"","color":"secondary","aria-label":_vm.$t(
                                                'site.general.changeMainColor'
                                            )}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-palette")])],1)]}}])},[_c('v-card',[_c('v-container',_vm._l((_vm.colorGrid),function(colorRow,rowIndex){return _c('v-row',{key:rowIndex},_vm._l((colorRow),function(colorEntry,colIndex){return _c('v-col',{key:colIndex,attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"fab":"","color":colorEntry.base},on:{"click":function($event){_vm.selectedThemeColor =
                                                            colorEntry}}},[(
                                                            _vm.selectedThemeColor ==
                                                            colorEntry
                                                        )?_c('v-icon',{attrs:{"color":"white","large":""}},[_vm._v("mdi-check")]):_vm._e()],1)],1)}),1)}),1)],1)],1),_c('v-btn',{attrs:{"fab":"","small":"","dark":!_vm.darkModeActive,"light":_vm.darkModeActive,"aria-label":_vm.$t('site.general.changeTheme')},on:{"click":_vm.toggleDarkmode}},[_c('v-icon',[_vm._v("mdi-theme-light-dark")])],1)],1),_c('v-list-item',{attrs:{"role":"complementary","aria-label":_vm.$t('site.component.navigation.userInfo')}},[_c('userInfoComponent')],1)],1)],1)],1),_c('v-divider'),_c('v-list',{attrs:{"nav":"","dense":""}},_vm._l((_vm.routes),function(route){return _c('v-list-item',{key:route.path,attrs:{"to":{
                        name: route.name,
                        params: { locale: _vm.locateUrl },
                    },"color":"primary"}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(route.meta.nav.icon))])],1),_c('v-list-item-title',[_vm._v(" "+_vm._s(route.meta.title())+" ")])],1)}),1)],1),(_vm.loggedIn)?_c('v-card',{attrs:{"align-end":"","flat":"","tile":""}},[_c('v-divider'),_c('stateTimeComponent',{attrs:{"role":"complementary","aria-label":_vm.$t('site.component.navigation.stateTimeInfo')}})],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }