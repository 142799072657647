import Vue from "vue";
import Vuex from "vuex";
import { config } from "vuex-module-decorators";
import { Color } from "vuetify/lib/util/colors";
import { readThemeColorSetting, setNewThemeColor } from "../plugins/vuetify";
// Set rawError to true by default on all @Action decorators
config.rawError = true;

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        isNavigationBarVisible: true,
        appVersion: process.env.PACKAGE_VERSION || "0",
        instanceName: "",
        existsNetworkError: false,
        selectedThemeColor: readThemeColorSetting()
    },
    mutations: {
        setNavigationBarVisible(state, val: boolean) {
            state.isNavigationBarVisible = val;
        },
        updateExistsNetworkError(state, val: boolean) {
            state.existsNetworkError = val;
        },
        setSelectedThemeColor(state, val: Color) {
            setNewThemeColor(val);
            state.selectedThemeColor = val;
        },
    },
});

export default store;
