<template>
    <v-app>
        <router-view name="appbar" />
        <router-view name="navigation" />
        <router-view />
    </v-app>
</template>

<script lang="ts">
import Vue from "vue";
import "@fontsource/roboto/latin.css";
import { storageKeyTheme } from "../plugins/vuetify";
import { appName } from "timepowerweb-common/config";
import { localStorageVersionUpdate } from "../config";
import { i18n } from "../plugins/i18n";
import ServerInfoModule from "../store/modules/serverinfo-module";

export default Vue.extend({
    name: appName.replace(" ", ""),
    mounted() {
        ServerInfoModule.retrieveServerInfo();
        const value = localStorage.getItem(localStorageVersionUpdate);
        if (value) {
            this.$toast.info(i18n.t("site.updateInfo"));
            localStorage.removeItem(localStorageVersionUpdate);
        }
        this.$vuetify.theme.dark = (localStorage.getItem(storageKeyTheme) === "true");
    },
});

</script>
