<template>
    <v-card class="fill-height">
        <v-container fill-height>
            <v-row v-for="btn in buttons" :key="btn.title()">
                <v-col>
                    <v-btn
                        block
                        @click="changeStatus(btn.type)"
                        :color="btn.color"
                        height="100"
                    >
                        {{ btn.title() }}
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
    </v-card>
</template>

<script lang="ts">
import Vue from "vue";
import { EnumParameterType } from "timepowerweb-common/model/company-model";
import { EnumEventType } from "timepowerweb-common/model/event-model";
import EventModule from "../store/modules/event-module";
import CompanyModule from "../store/modules/company-module";
import { i18n } from "../plugins/i18n";
import { colorTrackingStart, colorBreakRecreation, colorBreakProject, colorTrackingStop } from "../plugins/vuetify";

export default Vue.extend({
    name: "StatusControlComponent",
    computed: {
        buttons() {
            return [
                {
                    type: EnumEventType.TrackingActive,
                    color: colorTrackingStart,
                    title: () => i18n.t("object.eventType.trackingActive.actionLabel"),
                    display: () => EventModule.currentEvent.eventType !== EnumEventType.TrackingActive,
                },
                {
                    type: EnumEventType.TrackingChanged,
                    color: colorTrackingStart,
                    title: () => i18n.t("object.eventType.trackingChanged.actionLabel"),
                    display: () => true,
                },
                {
                    type: EnumEventType.BreakRecreation,
                    color: colorBreakRecreation,
                    title: () => CompanyModule.company.parameters[EnumParameterType.BreakRecreation]?.value ?? i18n.t("object.eventType.breakRecreation.actionLabel"),
                    display: () => EventModule.currentEvent.eventType !== EnumEventType.BreakRecreation
                        && EventModule.currentEvent.eventType !== EnumEventType.BreakProject,
                },
                {
                    type: EnumEventType.BreakProject,
                    color: colorBreakProject,
                    title: () => CompanyModule.company.parameters[EnumParameterType.BreakProject]?.value ?? i18n.t("object.eventType.breakProject.actionLabel"),
                    display: () => EventModule.currentEvent.eventType !== EnumEventType.BreakRecreation
                        && EventModule.currentEvent.eventType !== EnumEventType.BreakProject,
                },
                {
                    type: EnumEventType.TrackingStopped,
                    color: colorTrackingStop,
                    title: () => i18n.t("object.eventType.trackingStopped.actionLabel"),
                    display: () => true,
                },
            ]
                .filter((x) => x.display());
        },
    },
    methods: {
        changeStatus(type: EnumEventType): void {
            EventModule.updateEventTypeForNextEvent(type);
        },
    },
});
</script>
