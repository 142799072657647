import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import "@mdi/font/css/materialdesignicons.css"; // Ensure you are using css-loader
import de from "vuetify/src/locale/de";
import en from "vuetify/src/locale/en";
import { colors } from "vuetify/lib";
import { Color } from "vuetify/lib/util/colors";

Vue.use(Vuetify);

export const storageKeyTheme = "theme_isdark";
export const storageKeyMainColor = "theme_maincolor";

export const colorTrackingStart = "colortrackingstart";
export const colorBreakRecreation = "colorbreakrecreation";
export const colorBreakProject = "colorbreakproject";
export const colorTrackingStop = "colortrackingstop";
export const colorMenuTileBackground = "colormenutilebackground";
export const colorMainBackground = "colormainbackground";

export const DEFAULT_COLOR = colors.indigo;

export const colorList = [
    colors.red,
    colors.pink,
    colors.purple,
    colors.deepPurple,
    colors.indigo,
    colors.blue,
    colors.lightBlue,
    colors.cyan,
    colors.teal,
    colors.green,
    colors.lightGreen,
    colors.lime,
    colors.yellow,
    colors.amber,
    colors.orange,
    colors.deepOrange,
    createFullColor(colors.brown),
    createFullColor(colors.blueGrey),
    createFullColor(colors.grey),
];


//BaseColor must be defined itself, because it's not exported from vuetify
type BaseColor = Omit<Color, "accent1" | "accent2" | "accent3" | "accent4">;

/**
 * @param color
 */
function createFullColor(color: BaseColor): Color {
    return Object.assign({
        accent1: color.base,
        accent2: color.base,
        accent3: color.base,
        accent4: color.base,
    }, color);
}

const vuetify = new Vuetify({
    theme: {
        dark: false,
        themes: {
            light: {
                [colorTrackingStart]: colors.purple.lighten4,
                [colorBreakRecreation]: colors.green.lighten3,
                [colorBreakProject]: colors.yellow.darken2,
                [colorTrackingStop]: colors.red.lighten2,
            },
            dark: {
                [colorTrackingStart]: colors.purple.lighten2,
                [colorBreakRecreation]: colors.green.lighten1,
                [colorBreakProject]: colors.yellow.darken4,
                [colorTrackingStop]: colors.red,
            },
        },
    },
    lang: {
        locales: { de, en },
    },
});

applyThemeColor(readThemeColorSetting());

/**
 *
 * @param newColor - If newColor is undefined, use next color, else use passed param.
 */
export function setNewThemeColor(newColor: Color): void {
    let colorIndex;
    colorIndex = colorList.indexOf(newColor);

    if (colorIndex >= colorList.length || colorIndex < 0) {
        colorIndex = colorList.indexOf(DEFAULT_COLOR);
    }

    localStorage.setItem(storageKeyMainColor, colorIndex.toString());
    applyThemeColor(newColor);
}

/**
 *
 */
export function readThemeColorSetting(): Color {
    const colorSetting = localStorage.getItem(storageKeyMainColor);
    if (colorSetting !== null) {
        const index = parseInt(colorSetting);
        return !isNaN(index) && index >= 0 && index < colorList.length ? colorList[index] : DEFAULT_COLOR;
    }
    return DEFAULT_COLOR;
}

/**
 *
 * @param themeColor
 */
function applyThemeColor(themeColor: Color): void {
    vuetify.framework.theme.themes.light = updateFromPartial(vuetify.framework.theme.themes.light, {
        primary: themeColor,
        secondary: themeColor.lighten1,
        accent: themeColor.darken2,
        [colorMenuTileBackground]: themeColor.lighten4,
        [colorMainBackground]: themeColor.lighten5,
    });
    vuetify.framework.theme.themes.dark = updateFromPartial(vuetify.framework.theme.themes.dark, {
        primary: themeColor.lighten3,
        secondary: themeColor.lighten1,
        accent: themeColor.lighten3,
        [colorMenuTileBackground]: themeColor.darken4,
        [colorMainBackground]: colors.shades.black,
    });
}

/**
 * @param obj
 * @param updates
 */
function updateFromPartial<T>(obj: T, updates: Partial<T>): T {
    return { ...obj, ...updates };
}

export default vuetify;
