export function sortStr(a: string, b: string): number {
    const nameA = a.toUpperCase(); // ignore upper and lowercase
    const nameB = b.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
        return -1;
    }
    if (nameA > nameB) {
        return 1;
    }

    // names must be equal
    return 0;
}

export function isMacAddressValid(mac: string): boolean {
    const regex = /^([0-9A-F]{2}[:-]){5}([0-9A-F]{2})$/;
    const macFilled = !!mac;
    return macFilled && regex.test(mac);
}

export class JwtHelper {
    private static _urlBase64Decode(str: string) {
        let output = str.replace(/-/g, "+").replace(/_/g, "/");
        switch (output.length % 4) {
            case 0:
                break;
            case 2:
                output += "==";
                break;
            case 3:
                output += "=";
                break;
            default:
                // tslint:disable-next-line:no-string-throw
                throw "Illegal base64url string!";
        }
        return decodeURIComponent((<any>window).escape(window.atob(output)));
    }

    public static decodeToken(token = ""): unknown {
        if (token === null || token === "") { return { "upn": "" }; }
        const parts = token.split(".");
        if (parts.length !== 3) {

            throw new Error("JWT must have 3 parts");
        }
        const decoded = this._urlBase64Decode(parts[1]);
        if (!decoded) {
            throw new Error("Cannot decode the token");
        }
        return JSON.parse(decoded);
    }
}
