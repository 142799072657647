import { EnumTreeType, TimepowerEntry, TimepowerEntryList } from "timepowerweb-common/model/timepowerentry-model";

export type TreeNodeIdentification = {
    level: number,
    id: string,
};

/**
 *
 */
export class TreeNode {
    id!: string;
    name!: string; // Needed for name display.
    children!: TreeNodeList;
    isEndPoint!: boolean;
    comment!: string;
    isSelectable(): boolean {
        return this.children.length === 0;
    }
}

export type TreeNodeList = TreeNode[];

export type ArrayToTreeRes = {
    treeNodes: TreeNodeList,
    openedNodes: TreeNodeList,
};

/**
 *
 * @param timepowerList
 */
export function arrayToTree(timepowerList: TimepowerEntryList): ArrayToTreeRes {

    /*
    @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/Collator/Collator#syntax
    numeric:true => "CC 1" < "CC 2" < "CC 10" < "CC 42"
    sensitivity: "base" => case-insensitive and letters with accent are equal to base letters
    */
    const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: "base" });

    //Calculate Children
    /**
     *
     * @param node
     */
    function addChildren(node: TreeNode): void {
        const identification = convertStringToIdentification(node.id);
        const itemList = timepowerList.filter((x) => filterEntryWithParent(x, identification.level + 1, identification.id))
            .map((item) => {
                const newNode = Object.assign(new TreeNode, {
                    id: convertIdentificationToString({
                        level: item.kind,
                        id: item.id,
                    }),
                    name: item.description,
                    comment: item.comment,
                    children: [],
                    isEndPoint: item.kind === EnumTreeType.InternalOrder,
                });

                return newNode;
            });
        itemList.filter((x) => !x.isEndPoint).forEach((x) => addChildren(x));
        //add children sort by name with collator.compare.
        node.children.push(...itemList.sort((x, y) => collator.compare(x.name, y.name)));
    }

    const rootNode = Object.assign(new TreeNode, {
        id: convertIdentificationToString({
            level: -2,
            id: "root",
        }),
        name: "root",
        children: [],
        isEndPoint: false,
    });

    addChildren(rootNode);

    return {
        treeNodes: rootNode.children ?? [],
        openedNodes: rootNode.children ?? [],
    };
}

const ID_SPLITTER = "_";

/**
 *
 * @param identification
 */
function convertIdentificationToString(identification: TreeNodeIdentification): string {
    return identification.level + ID_SPLITTER + identification.id;
}

/**
 *
 * @param idString
 */
function convertStringToIdentification(idString: string): TreeNodeIdentification {
    const splitString = idString.split(ID_SPLITTER);

    return {
        level: Number(splitString[0]),
        id: splitString[1],
    };
}

/**
 *
 * @param timepowerEntry
 * @param idString
 */
export function filterEntryWithId(timepowerEntry: TimepowerEntry, idString: string): boolean {
    const id = convertStringToIdentification(idString);
    return timepowerEntry.kind == id.level
        && timepowerEntry.id.toLowerCase() == id.id;
}

/**
 *
 * @param timepowerEntry
 * @param level
 * @param parent
 */
export function filterEntryWithParent(timepowerEntry: TimepowerEntry, level: number, parent: string): boolean {
    return timepowerEntry.kind == level
        && timepowerEntry.parent.toLowerCase() == parent;
}
