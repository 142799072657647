<template>
    <section
        class="d-flex flex-column"
        :aria-label="$t('site.component.statusinfo.sectionLabel')"
    >
        <statusComponent type="0" :event="currentEvent" />
        <statusComponent type="1" :event="nextEvent" />

        <v-btn
            color="secondary"
            v-if="buttonVisible"
            :disabled="buttonDisabled"
            block
            max-height="100"
            class="align-self-end mt-auto"
            @click="saveEvent"
        >
            {{ $t("site.general.apply") }}
        </v-btn>
        <v-dialog v-model="dialog" width="80%">
            <v-card>
                <v-card flat tile :color="backgroundColor" class="pa-3">
                    <reportingComponent :runFirstReport="dialog" />
                </v-card>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" @click="dialog = false">
                        {{ $t("site.general.close") }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </section>
</template>

<script lang="ts">
import Vue from "vue";
import StatusComponent from "./status-component.vue";
import EventModule from "../store/modules/event-module";
import UserModule from "../store/modules/user-module";
import ReportingComponent from "./reporting-component.vue";
import { EnumEventType } from "timepowerweb-common/model/event-model";
import { colorMainBackground } from "../plugins/vuetify";
import { catClient } from "../helper/logging";

export default Vue.extend({
    name: "StatusInfoComponent",
    data: () => ({
        dialog: false,
    }),
    components: {
        statusComponent: StatusComponent,
        reportingComponent: ReportingComponent,
    },
    computed: {
        currentEvent: () => EventModule.currentEvent,
        nextEvent: () => EventModule.nextEvent,
        nextEventType: () => EventModule.nextEvent.eventType,
        buttonDisabled: () => EventModule.nextEvent.internalOrder === undefined
            || EventModule.nextEvent.eventType === EventModule.currentEvent.eventType,
        // Show Button if Quickmode is disabled OR Quickmode is enabled and user is not tracking
        buttonVisible: () => !UserModule.user.settings.quickModeEnabled
            || (UserModule.user.settings.quickModeEnabled
                && !EventModule.isInternalOrderForNextEventChangeable),
        backgroundColor: () => colorMainBackground,
    },
    methods: {
        saveEvent() {
            EventModule.saveNextEvent()
                .then((runSuccess) => {
                    if (runSuccess
                        && this.currentEvent.eventType === EnumEventType.TrackingStopped) {
                        this.dialog = true;
                    }
                    UserModule.updateUserStateTimes();
                    return;
                })
                .catch((error) => catClient.error("saveEvent", error));
        },
    },
    watch: {
        nextEventType: {
            handler() {
                if (!this.buttonVisible && !this.buttonDisabled
                    && this.currentEvent.eventType !== this.nextEvent.eventType) {
                    this.saveEvent();
                }
            },
            immediate: true,
        },
    },
});
</script>
