<template>
    <v-card flat tile width="100%" color="transparent">
        <v-progress-linear indeterminate v-if="loading" />
        <div v-if="!loading">
            <p class="text-h6">{{ userDisplayName }}</p>
            <p class="text-subtitle-2 text-grey" v-if="loggedIn">
                {{ $t("site.component.userInfo.personnelNumber") }}:
                {{ user.id }}
                <br />
                <v-select
                    :label="$t('site.component.userInfo.location')"
                    :items="companies"
                    class="mt-2 mb-0 pb-0"
                    outlined
                    dense
                    full-width
                    item-text="description"
                    item-value="id"
                    v-model="company"
                    return-object
                    v-if="!onlyOneCompany"
                />
                <span v-if="onlyOneCompany">
                    {{ $t("site.component.userInfo.location") }}:
                    {{ company.description }}
                </span>
                <br />
                {{ $t("object.event.workLocationLabel") }}:
                {{ labelWorkStatus }}
            </p>
        </div>
    </v-card>
</template>

<script lang="ts">
import Vue from "vue";
import UserModule from "../store/modules/user-module";
import CompanyModule from "../store/modules/company-module";
import { Company, CompanyList } from "timepowerweb-common/model/company-model";
import CompanyService from "../services/company-service";
import { getLocaleToUrlLocale, i18n } from "../plugins/i18n";
import { catClient } from "../helper/logging";

export default Vue.extend({
    name: "UserInfoComponent",
    data: () => ({
        companies: [] as CompanyList,
        loading: false,
    }),
    computed: {
        user() {
            return UserModule.user;
        },
        userDisplayName() {
            return UserModule.userDisplayName;
        },
        loggedIn() {
            return UserModule.isLoggedIn;
        },
        onlyOneCompany() {
            return this.companies.length == 1;
        },
        labelWorkStatus() {
            return UserModule.user.settings.isInHomeOffice ? i18n.t("object.user.settings.workStatusAtHome") : i18n.t("object.user.settings.workStatusInOffice");
        },
        company: {
            get() {
                return CompanyModule.company;
            },
            set(val: Company) {
                CompanyModule.storeCompany(val);
            },
        },
        locale() {
            return this.$i18n.locale;
        },
    },
    methods: {
        updateUserAndCompany(localeChange = false) {
            if (!this.loading) {
                this.loading = true;

                const compId = this.company.id;
                //catVue.debug("updateUserAndCompany, set loading to true.");
                UserModule.updateUser()
                    .then(() => {
                        this.companies.length = 0;

                        if (this.loggedIn) {
                            return CompanyService.getCompanyList();
                        } else {
                            return [];
                        }
                    })
                    .then((companies) => {
                        this.companies.push(...companies);
                        this.company = this.companies.find((x) => x.id == compId) ?? this.companies[0];
                        if (localeChange) {
                            this.$toast.success(this.$i18n.t("site.general.languageLoaded", [getLocaleToUrlLocale(this.locale)]));
                        }

                        if (!localeChange && !!this.user.settings.passwordChangeInDays) {
                            const timeUntil = i18n.tc("object.user.settings.passwordChangeDays", this.user.settings.passwordChangeInDays);
                            this.$toast.warning(i18n.t("object.user.settings.passwordChangeDaysToast", [timeUntil]));
                        }

                        return true;
                    })
                    .catch((error) => catClient.error("userChangeHandler", error))
                    .finally(() => {
                        this.loading = false;
                    });
            }
        },
    },
    watch: {
        loggedIn: {
            handler(newValue: boolean, oldValue: boolean) {
                if (!newValue) {
                    this.loading = false;
                    //catVue.debug("loggedIn !newValue, set loading to false.");
                }

                if (newValue !== oldValue) {
                    this.updateUserAndCompany();
                }
            },
            immediate: true,
        },
        locale: {
            handler(newValue, oldValue) {
                if (newValue !== oldValue
                    && this.loggedIn
                    && oldValue !== undefined) {
                    this.updateUserAndCompany(true);

                }
            },
            immediate: true,
        },
    },
});
</script>
