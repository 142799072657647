<template>
    <v-card flat class="pa-2">
        <div class="d-flex my-2">
            <div class="align-self-center">
                <v-icon>mdi-timetable</v-icon>
                {{ $t("site.component.stateTime.title") }}
            </div>
            <v-spacer />
            <v-btn @click="updateTimes(false)" color="secondary">
                {{ $t("site.general.refresh") }}
            </v-btn>
        </div>

        <v-simple-table dense aria-label="StateTimes">
            <thead>
                <tr>
                    <th />
                    <th class="text-left">
                        {{ $t("site.component.stateTime.timespanLabel") }}
                    </th>
                    <th>
                        {{ $t("site.component.stateTime.minuteLabel") }}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <th>
                        {{ $t("object.eventType.trackingActive.shortLabel") }}
                    </th>
                    <td>{{ activeTime.time }}</td>
                    <td>{{ activeTime.minutes }}</td>
                </tr>
                <tr>
                    <th>{{ breakProjectTitle }}</th>
                    <td>{{ breakProjectTime.time }}</td>
                    <td>{{ breakProjectTime.minutes }}</td>
                </tr>
                <tr>
                    <th>{{ breakRecreationTitle }}</th>
                    <td>{{ breakRecreationTime.time }}</td>
                    <td>{{ breakRecreationTime.minutes }}</td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <th colspan="3" class="text-right accent--text">
                        {{ lastUpdatedAt }}
                    </th>
                </tr>
            </tfoot>
        </v-simple-table>
    </v-card>
</template>

<script lang="ts">

import Vue from "vue";
import { EnumEventType } from "timepowerweb-common/model/event-model";
import { StateTime, TimeClass, TimeClassMap } from "timepowerweb-common/model/statetime-model";
import UserModule from "../store/modules/user-module";
import CompanyModule from "../store/modules/company-module";
import { EnumParameterType } from "timepowerweb-common/model/company-model";
import { i18n } from "../plugins/i18n";
import { catClient } from "../helper/logging";

const updateIntervalInSec = 300;
const blockManualUpdateInSec = 30;

export default Vue.extend({
    name: "StateTimeComponent",
    data: () => ({
        interval: undefined as unknown as ReturnType<typeof setTimeout>,
        canUpdateTimes: true,
    }),
    mounted() {
        this.setStateTimeInterval();
    },
    computed: {
        stateTimes() {
            return getStateTimes();
        },
        activeTime() {
            return calculatedTimes(getStateTimes().times, EnumEventType.TrackingActive);
        },
        breakProjectTime() {
            return calculatedTimes(getStateTimes().times, EnumEventType.BreakProject);
        },
        breakRecreationTime() {
            return calculatedTimes(getStateTimes().times, EnumEventType.BreakRecreation);
        },
        breakProjectTitle() {
            return CompanyModule.company.parameters[EnumParameterType.BreakProject]?.value3 ?? this.$i18n.t("object.eventType.breakProject.shortLabel");
        },
        breakRecreationTitle() {
            return CompanyModule.company.parameters[EnumParameterType.BreakRecreation]?.value3 ?? this.$i18n.t("object.eventType.breakRecreation.shortLabel");
        },
        lastUpdatedAt() {
            return i18n.t("site.component.stateTime.updateTimeLabel", [getStateTimes().updateDate]);
        },
    },
    methods: {
        updateTimes(auto = true) {
            if (this.canUpdateTimes
                || auto) {
                this.canUpdateTimes = false;
                UserModule.updateUserStateTimes()
                    .catch(() => catClient.error("No statetimes retrieve.", null))
                    .finally(() => {
                        setTimeout(() => { this.canUpdateTimes = true; }, blockManualUpdateInSec * 1000);
                        this.setStateTimeInterval();
                    });
            } else if (!auto) {
                this.$toast.info(this.$i18n.t("site.component.stateTime.infoTextWaitTime", [blockManualUpdateInSec]));
            }
        },
        setStateTimeInterval() {
            this.clearStateTimeInterval();
            // First values come with the loading of the user, so no extra updateTimes must be called.
            if (updateIntervalInSec && updateIntervalInSec > 0) {
                this.interval = setInterval(this.updateTimes, updateIntervalInSec * 1000);
            }
        },
        clearStateTimeInterval() {
            if (this.interval) {
                clearInterval(this.interval);
            }
        },
    },
    destroyed() {
        this.clearStateTimeInterval();
    }
});

function getStateTimes() {
    return UserModule.user.stateTimes ?? new StateTime();
}

function calculatedTimes(times: TimeClassMap | undefined, eventType: EnumEventType): TimeClass {
    if (times !== undefined && times[eventType] !== undefined) {
        return times[eventType] as TimeClass;
    }
    return {
        time: "00:00:00",
        minutes: 0,
    };

}
</script>
