import moment from "moment";
import * as workerTimers from "worker-timers";
import { appName } from "timepowerweb-common/config";
import { catClient } from "../helper/logging";
import { i18n } from "../plugins/i18n";
import PepWarningService from "../services/pepwarnings-service";
import UserModule from "../store/modules/user-module";

/*
  Module "worker-timers" is needed to run the interval checks,
  when the browser is in the background or another tab is select.
*/
let timerIdPepWarning = 0;

const PEP_API_RUN = "PEP_API_RUN";
const PEP_LAST_RUNTIME = "PEP_LAST_RUNTIME";
const NOTIFICATION_TIMEOUT = 20000;

const intervalPepWarningInMs = 60000;
const overlapTimePepWarningInMs = intervalPepWarningInMs - 5000;
const notificationList = [] as Notification[];

/**
 *
 */
export function unregisterPepTask(): void {
    if (timerIdPepWarning != 0) {
        workerTimers.clearInterval(timerIdPepWarning);
        timerIdPepWarning = 0;
    }
    notificationList.forEach((x) => x.close());
    notificationList.length == 0;
    localStorage.removeItem(PEP_API_RUN);
    localStorage.removeItem(PEP_LAST_RUNTIME);
}

/**
 *
 */
export function registerPepTask(): void {
    if (timerIdPepWarning != 0) {
        unregisterPepTask();
    }
    localStorage.removeItem(PEP_API_RUN);
    timerIdPepWarning = createIntervalWithDirectStart(intervalPepWarningInMs, runPepWarnings);
    self.addEventListener("beforeunload", unregisterPepTask);
}

/**
 *
 * @param intervalInMs
 * @param fnc
 */
function createIntervalWithDirectStart(intervalInMs: number, fnc: () => void): number {
    fnc();
    return workerTimers.setInterval(fnc, intervalInMs);
}

/**
 *
 * @param notification
 */
function clearNotification(notification: Notification): void {
    const index = notificationList.indexOf(notification);
    if (index > -1) {
        notification.close();
        notificationList.splice(index, 1);
    }
}

/**
 *
 */
function runPepWarnings(): Promise<unknown> | void {
    const PEP_CHECK_VALUE = "true";

    // Only run if currently not running or not run in the last 'overlapTimePepWarningInMs'
    const lastRun = Number(localStorage.getItem(PEP_LAST_RUNTIME));
    if (localStorage.getItem(PEP_API_RUN) !== PEP_CHECK_VALUE
        && (isNaN(lastRun)
            || moment(lastRun).add(overlapTimePepWarningInMs).isBefore())) {
        localStorage.setItem(PEP_API_RUN, PEP_CHECK_VALUE);
        return createPepNotifications()
            .finally(() => {
                localStorage.removeItem(PEP_API_RUN);
                localStorage.setItem(PEP_LAST_RUNTIME, moment().valueOf().toString());
            });
    }
}

/**
 *
 */
function checkNotificationPermission(): Promise<boolean> {
    if (!("Notification" in window)) {
        alert(i18n.t("site.notification.warningNotificationNotSupported").toString());
        throw false;
    }

    if (Notification.permission === "default") {
        return Notification.requestPermission()
            .then((permission) => (permission === "granted"));
    } else {
        return Promise.resolve(Notification.permission === "granted");
    }
}

/**
 *
 */
function createPepNotifications(): Promise<unknown> {
    return checkNotificationPermission()
        .then((canLoad) => {
            if (canLoad) {
                return PepWarningService.getPepWarnings();
            } else {
                alert(i18n.t("site.notification.warningNoPopupsGranted").toString());
                localStorage.removeItem(PEP_API_RUN);
                throw false;
            }
        })
        .then((pepList) => {
            const timestamp = new Date().getTime();
            pepList.forEach((pepWarning) => {
                const timeUntil = i18n.tc("object.pepwarning.timeUntil", pepWarning.startInMin);
                const notification = new Notification(
                    appName + " - " + i18n.t("object.pepwarning.title").toString(),
                    {
                        tag: timestamp.toString() + "_" + pepWarning.startTime.toString(),
                        body: i18n.t("object.pepwarning.bodyNotification", [pepWarning.description, moment(pepWarning.startTime).format("LT"), timeUntil, pepWarning.internalOrder]).toString(),
                        icon: "/images/applicationLogoWhiteBackground256x256.png",
                        silent: UserModule.user.settings.silentNotification,
                    },
                );
                workerTimers.setTimeout(() => clearNotification(notification), NOTIFICATION_TIMEOUT);
                notificationList.push(notification);
            });
            return true;
        })
        .catch((error) => catClient.error("retrievePepList", error));
}
