import { EnumParameterType } from "timepowerweb-common/model/company-model";
import { EnumEventType } from "timepowerweb-common/model/event-model";
import { i18n } from "../plugins/i18n";
import CompanyModule from "../store/modules/company-module";

/**
 *
 * @param eventType
 * @param short
 */
export function eventTypeToString(eventType: EnumEventType, short = true): string {
    switch (eventType) {
        case EnumEventType.TrackingChanged:
        case EnumEventType.TrackingActive: {
            return i18n.t("object.eventType.trackingActive.shortLabel").toString();
        }
        case EnumEventType.TrackingStopped: {
            return i18n.t("object.eventType.trackingStopped.shortLabel").toString();
        }
        case EnumEventType.BreakRecreation: {
            return (short ? (CompanyModule.company.parameters[EnumParameterType.BreakRecreation]?.value3 as string) :
                (CompanyModule.company.parameters[EnumParameterType.BreakRecreation]?.value as string))
                ?? i18n.t("object.eventType.breakRecreation.shortLabel").toString();
        }
        case EnumEventType.BreakProject: {
            return (short ? (CompanyModule.company.parameters[EnumParameterType.BreakProject]?.value3 as string) :
                (CompanyModule.company.parameters[EnumParameterType.BreakProject]?.value as string))
                ?? i18n.t("object.eventType.breakProject.shortLabel").toString();
        }
        default: {
            return "";
        }
    }
}
