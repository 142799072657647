import { plainToInstance } from "class-transformer";
import { InternalOrder } from "timepowerweb-common/model/internalorder-model";
import { TypeOfIdentificationProperty } from "timepowerweb-common/model/types";
import { WorkType, WorkTypeList } from "timepowerweb-common/model/worktype-model";
import { WORKTYPE_INTERNALORDER_ROUTE } from "timepowerweb-common/routes";
import axios, { ComposedError } from "../helper/axios";

/**
 *
 */
export default class WorkTypeService {
    static getWorkTypeList(internalOrderId: TypeOfIdentificationProperty<InternalOrder>): Promise<WorkTypeList> {
        return axios.get(WORKTYPE_INTERNALORDER_ROUTE + internalOrderId)
            .then((res) => plainToInstance(WorkType, res.data as []))
            .catch((error: ComposedError) => {
                // do not handle it globally because it will throw
                // an error toast on failed login
                throw error.handleGlobally("getWorkTypeList");
            });
    }
}
