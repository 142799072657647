import { plainToInstance } from "class-transformer";
import { EventTimepower } from "timepowerweb-common/model/event-model";
import { EVENT_CURRENT_ROUTE, EVENT_ROUTE } from "timepowerweb-common/routes";
import axios, { ComposedError } from "../helper/axios";

/**
 *
 */
export default class EventService {
    static retrieveCurrentEvent(): Promise<EventTimepower> {
        return axios.get(EVENT_CURRENT_ROUTE)
            .then((res) => plainToInstance(EventTimepower, res.data))
            .catch((error: ComposedError) => {
                // do not handle it globally because it will throw
                // an error toast on failed login
                throw error.handleGlobally("getCurrentEvent");
            });
    }

    static postNextEvent(event: EventTimepower): Promise<EventTimepower> {
        return axios.post(EVENT_ROUTE, event)
            .then((res) => plainToInstance(EventTimepower, res.data))
            .catch((error: ComposedError) => {
                // do not handle it globally because it will throw
                // an error toast on failed login
                throw error.handleGlobally("postNextEvent");
            });
    }
}
