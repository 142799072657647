import { plainToInstance } from "class-transformer";
import { Company, CompanyList } from "timepowerweb-common/model/company-model";
import { COMPANY_ROUTE } from "timepowerweb-common/routes";
import axios, { ComposedError } from "../helper/axios";

/**
 *
 */
export default class CompanyService {
    static getCompanyList(): Promise<CompanyList> {
        return axios.get(COMPANY_ROUTE)
            .then((res) => plainToInstance(Company, res.data as []))
            .catch((error: ComposedError) => {
                // do not handle it globally because it will throw
                // an error toast on failed login
                throw error.handleGlobally("getCompanies");
            });
    }
}
