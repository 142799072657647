<template>
    <div class="about">
        <aboutComponent />
    </div>
</template>

<script lang="ts">

import Vue from "vue";
import AboutComponent from "../components/about-component.vue"; // @ is an alias to /src

export default Vue.extend({
    name: "About",
    components: {
        aboutComponent: AboutComponent,
    },
});
</script>
