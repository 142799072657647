<template>
    <v-card>
        <v-form v-model="valid" ref="form" class="text-left">
            <v-card-title>
                <v-icon>mdi-account-lock-outline</v-icon>
                &nbsp; {{ $t("site.component.settings.changePassword") }}
            </v-card-title>
            <v-card-subtitle>
                <span v-if="subtitle"> {{ subtitle }} </span>
                {{
                    $t("site.component.password.changePasswortSubtitle", [
                        appName,
                    ])
                }}
            </v-card-subtitle>
            <v-card-text>
                <passwordFieldComponent
                    ref="oldPwd"
                    :label="passwordOldLabel"
                    v-model="passwordOld"
                    v-if="!password"
                    @keyDownCapsLock="keyDownCapsLock"
                />
                <passwordFieldComponent
                    ref="newPwd"
                    :label="passwordNewLabel"
                    v-model="passwordNew"
                    :rules="[
                        ...passwordRuleValidation,
                        ...passwordCnxDeRuleValidation,
                    ]"
                    @keyDownCapsLock="keyDownCapsLock"
                />
                <passwordFieldComponent
                    ref="newConfirmPwd"
                    :label="passwordNewConfirmLabel"
                    v-model="passwordNewConfirm"
                    :rules="[
                        ...passwordRuleValidation,
                        ...passwordConfirmValidation,
                    ]"
                    :keydownenter="submit"
                    @keyDownCapsLock="keyDownCapsLock"
                />
            </v-card-text>
            <v-card-actions>
                <v-card-text
                    v-if="isCapsLockActive"
                    class="py-0 error--text font-weight-bold text-left"
                >
                    {{ $t("site.component.password.capsLockOnWarning") }}
                </v-card-text>
                <v-spacer />
                <v-btn
                    @click="submit"
                    :class="{
                        primary: valid,
                    }"
                    :disabled="!valid"
                    color="secondary"
                    >{{ $t("site.component.password.changePassword") }}
                </v-btn>
            </v-card-actions>
        </v-form>
    </v-card>
</template>

<script lang="ts">
import Vue from "vue";
import UserModule from "../store/modules/user-module";
import { i18n } from "../plugins/i18n";
import PasswordFieldComponent, { PasswordField } from "./passwordfield-component.vue";
import { TranslateResult } from "vue-i18n";
import { EnumErrorCode } from "timepowerweb-common/error";
import { appName } from "timepowerweb-common/config";

export default Vue.extend<{ valid: boolean, passwordOld: string, passwordNew: string, passwordNewConfirm: string, isCapsLockActive: boolean, },
    { submit: () => void, reset: () => void, keyDownCapsLock: (data: boolean) => void, },
    {
        passwordConfirmValidation: (true | TranslateResult)[], passwordRuleValidation: ((v: string) => boolean | TranslateResult)[],
        locale: string,
        passwordCnxDeRuleValidation: ((v: string) => boolean | TranslateResult)[], passwordOldLabel: TranslateResult, passwordNewLabel: TranslateResult, passwordNewConfirmLabel: TranslateResult,
    },
    { token: string, password: string, subtitle: string, }>({
        name: "PasswordChangeComponent",
        components: {
            passwordFieldComponent: PasswordFieldComponent,
        },
        props: { token: String, password: String, subtitle: String },
        data() {
            return {
                valid: false,
                passwordOld: this.password,
                passwordNew: "",
                passwordNewConfirm: "",
                isCapsLockActive: false,
                appName: appName,
            };
        },
        computed: {
            passwordConfirmValidation() {
                return [this.passwordNew === this.passwordNewConfirm || this.$i18n.t("site.component.password.passwordDoNotMatch")];
            },
            passwordRuleValidation() {
                return [(v: string) => (!!v) || this.$i18n.t("site.general.passwordRequired")];
            },
            passwordCnxDeRuleValidation() {
                return [(v: string) => (v.length >= 11) || this.$i18n.t("site.component.password.passwordNotLongEnough"),
                (v: string) => isPasswordStrongEnough(v) || this.$i18n.t("site.component.password.passwordNotComplexEnoughField"),
                (v: string) => v !== this.passwordOld || this.$i18n.t("site.component.password.passwortNotDifferent"),
                ];
            },
            passwordOldLabel() {
                return this.$i18n.t("site.component.password.passwordOldLabel");
            },
            passwordNewLabel() {
                return this.$i18n.t("site.component.password.passwordNewLabel");
            },
            passwordNewConfirmLabel() {
                return this.$i18n.t("site.component.password.passwordNewConfirmLabel");
            },
            locale() {
                return this.$i18n.locale;
            }
        },
        methods: {
            keyDownCapsLock(data: boolean) {
                this.isCapsLockActive = data;
            },
            submit() {
                UserModule.changePassword({
                    passwordOld: this.passwordOld,
                    passwordNew: this.passwordNew,
                    token: this.token ?? UserModule.token,
                })
                    .then((res) => {
                        if (!res.result) {
                            throw EnumErrorCode.USER_PASSWORD_UNKNOWN;
                        }
                        this.reset();
                        Vue.$toast.success(i18n.t("site.component.password.passwordChangedSuccessfully"));
                        this.$emit("submit");
                        return;
                    })
                    .catch((err) => {
                        console.log(err);
                        switch (err) {
                            case EnumErrorCode.USER_PASSWORD_OLDPASSWORD_WRONG:
                                Vue.$toast.error(i18n.t("site.component.password.passwordChangeFailedWrongOld"));
                                break;
                            case EnumErrorCode.USER_PASSWORD_NOT_COMPLEX:
                                Vue.$toast.error(i18n.t("site.component.password.passwordChangeFailedNotComplexEnough"));
                                break;
                            default:
                                Vue.$toast.error(i18n.t("site.component.password.passwordChangeFailed"));
                        }

                    });
            },
            reset() {
                (this.$refs.oldPwd as PasswordField)?.reset();
                (this.$refs.newPwd as PasswordField)?.reset();
                (this.$refs.newConfirmPwd as PasswordField)?.reset();
            },
        },
        watch: {
            locale: {
                handler(newValue, oldValue) {
                    if (newValue !== oldValue
                        && oldValue !== undefined) {
                        (this.$refs.form as Element & { validate(): void, }).validate();
                    }
                },
                immediate: true,
            },
        }
    });

function isPasswordStrongEnough(p: string): boolean {
    var anUpperCase = /[A-Z]/;
    var aLowerCase = /[a-z]/;
    var aNumber = /[0-9]/;

    var numUpper = 0;
    var numLower = 0;
    var numNums = 0;
    var numSpecials = 0;
    for (var i = 0; i < p.length; i++) {
        if (anUpperCase.test(p[i]))
            numUpper = 1;
        else if (aLowerCase.test(p[i]))
            numLower = 1;
        else if (aNumber.test(p[i]))
            numNums = 1;
        else
            numSpecials = 1;
    }

    return ((numUpper + numLower + numNums + numSpecials) >= 3);
}
</script>
