import { plainToInstance } from "class-transformer";
import { EnumErrorCode, ErrorResponseType } from "timepowerweb-common/error";
import { StateTime } from "timepowerweb-common/model/statetime-model";
import { ChangePassword, DefaultResult, LoginResponse, LoginUser, User, UserSettings } from "timepowerweb-common/model/user-model";
import { STATETIME_ROUTE, USER_LOGIN_ROUTE, USER_PASSWORD_ROUTE, USER_SETTINGS_ROUTE, USER_OWN_ROUTE } from "timepowerweb-common/routes";
import axios, { ComposedError } from "../helper/axios";

/**
 *
 */
export default class UserService {
    // Login
    static login(user: LoginUser): Promise<LoginResponse> {
        return axios.post(USER_LOGIN_ROUTE, user)
            .then((res: { status: number, data: LoginResponse, }) => {
                if (res.status === 201
                    && res.data.token) { // Login successful
                    // handle success
                    return res.data;
                } else {
                    throw false;
                }
            });
    }

    static changePassword(changePassword: ChangePassword): Promise<DefaultResult> {
        return axios.post(USER_PASSWORD_ROUTE, changePassword)

            .catch((error) => {
                // do not handle it globally because it will throw
                // an error toast on failed login
                throw error.handleGlobally("changePassword");
            })
            .then((res) => {
                if (res.status === 201) { // Password change successful
                    return res.data;
                } else {
                    const resError = res.data as unknown as ErrorResponseType;
                    if (resError.errorCode) {
                        throw EnumErrorCode[resError.errorCode];
                    }

                    throw false;
                }
            });
    }

    static updateUserSettings(settings: UserSettings): Promise<User> {
        return axios.post(USER_SETTINGS_ROUTE, settings)
            .catch((error: ComposedError) => {
                // do not handle it globally because it will throw
                // an error toast on failed login
                throw error.handleGlobally("updateUserSettings");
            })
            .then((res: { status: number, data: User, }) => {
                if (res.status === 201) { // Password change successful
                    return res.data;
                } else {
                    throw false;
                }
            });
    }

    static retrieveUserSettings(): Promise<UserSettings> {
        return axios.get(USER_SETTINGS_ROUTE)
            .then((res) => plainToInstance(UserSettings, res.data))
            .catch((error: ComposedError) => {
                // do not handle it globally because it will throw
                // an error toast on failed login
                throw error.handleGlobally("retrieveUserSettings");
            });
    }

    static updateTimes(): Promise<StateTime> {
        return axios.get(STATETIME_ROUTE)
            .then((res) => plainToInstance(StateTime, res.data))
            .catch((error: ComposedError) => {
                // do not handle it globally because it will throw
                // an error toast on failed login
                throw error.handleGlobally("updateTimes");
            });
    }

    static getOwnUser(): Promise<User> {
        return axios.get(USER_OWN_ROUTE)
            .then((res) => plainToInstance(User, res.data))
            .catch((error: ComposedError) => {
                // We want to handle globally
                throw error.handleGlobally("getOwnUser");
            });
    }
}
