<template>
    <v-data-table
        dense
        :headers="headers"
        :items="items"
        :item-key="keyValue"
        :footer-props="footerProps"
        :single-select="isSingleSelect"
        :value="value"
        @input="doValueChange"
        @click:row="doClick"
        :loading="loading"
        :show-select="internalShowSelect"
    >
        <template
            v-for="header in headers.filter((header) =>
                header.hasOwnProperty('formatter')
            )"
            v-slot:[`item.${header.value}`]="{ value }"
        >
            {{ header.formatter(value) }}
        </template>
        <template v-slot:[`item.data-table-select`]="{ item, isSelected }">
            <v-checkbox
                :value="isSelected"
                hide-details
                class="mt-0 pt-0"
                @click.native.prevent.stop.capture="
                    onItemSelect(item, !isSelected)
                "
            >
            </v-checkbox>
        </template>
        <template v-slot:[`footer.prepend`]>
            <v-tooltip bottom v-if="!!isExportAllowed">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        dense
                        fab
                        small
                        depressed
                        v-bind="attrs"
                        v-on="on"
                        @click="exportToExcel"
                    >
                        <v-icon>mdi-file-download-outline</v-icon>
                    </v-btn>
                </template>
                {{ $t("site.component.table.exportToExcel") }}
            </v-tooltip>
        </template>
    </v-data-table>
</template>

<script lang="ts">
import Vue from "vue";
import xlsx from "json-as-xlsx";
import { DataTableHeader } from "vuetify";
import moment from "moment";
import { appName } from "timepowerweb-common/config";

export default Vue.extend({
    name: "TableComponent",
    props: [
        "headers",
        "items",
        "keyValue",
        "isSingleSelect",
        "loading",
        "value",
        "showSelect",
        "hasAtLeastOnSelected",
        "tableName",
        "isExportAllowed",
    ],
    data: () => ({
        footerProps: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            "items-per-page-options": [10, 50, 100, -1],
        },
    }),
    computed: {
        internalShowSelect() {
            return this.showSelect ?? false;
        },
    },
    methods: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        doClick(_selection: any, data: any) {
            data.select(true);
        },
        doValueChange(selection: any) {
            this.$emit("input", selection);
        },
        onItemSelect(item: any, value: boolean) {
            if (value) {
                this.doValueChange(this.isSingleSelect ? [item] : [...this.value, item]);
            } else {
                const newList = this.value.filter((x: any) => x !== item);
                if ((this.hasAtLeastOnSelected === true) && (newList.length == 0)) {
                    this.$emit("input", this.value);
                } else {
                    this.$emit("input", newList);
                }
            }
        },
        exportToExcel() {
            const data = [{
                sheetName: this.calculcateTableName(),
                columns: this.headers.map((x: DataTableHeader) => ({ label: x.text, value: x.value })),
                content: this.items,
            }];
            let settings = {
                fileName: this.calculcateTableName() + moment().format("_YYYYMMDD_HHmmss"), // Name of the spreadsheet
                extraLength: 3, //
                writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
            };
            xlsx(data, settings);
        },
        calculcateTableName(): string {
            return this.tableName ?? appName;
        },
    },
});
</script>
