<template>
    <div>
        <v-text-field
            v-model="search"
            :label="$t('site.general.searchEntries')"
            flat
            clearable
            clear-icon="mdi-close-circle-outline"
            prepend-icon="mdi-magnify"
        />
        <v-card flat class="overflow-y-auto pa-1" max-height="63vh !important">
            <v-treeview
                class="treeview-single-select"
                dense
                activatable
                :items="items"
                open-on-click
                :search="search"
                :filter="filter"
                v-model="selection"
                :open="open"
                :active.sync="selection"
                @input="doValueChange"
                return-object
            >
                <template v-slot:prepend="{ item, selected }">
                    <v-checkbox
                        dense
                        :value="selected"
                        color="primary"
                        hide-details
                        class="mt-0 pt-0"
                        v-if="item.children.length == 0"
                    ></v-checkbox>
                </template>

                <template v-slot:label="{ item }">
                    <v-row no-gutters align="center">
                        {{ item.name }}
                        &nbsp;
                        <small class="accent--text">
                            {{ item.comment }}
                        </small>
                    </v-row>
                </template>
            </v-treeview>
        </v-card>
    </div>
</template>

<script lang="ts">
import Vue from "vue";
import { TreeNode, TreeNodeList } from "../helper/treetools";

export default Vue.extend({
    name: "TreeComponent",
    props: [
        "items",
        "value",
        "open",
    ],
    data: () => ({
        search: null,
        selection: [] as TreeNodeList,
    }),
    computed: {
        filter() {
            return (item: TreeNode, search: string) =>
                (item.name.toLowerCase().indexOf(search.toLowerCase()) > -1) || (item.comment.toLowerCase().indexOf(search.toLowerCase()) > -1);
        },
        openEntries() {
            return open ?? [];
        },
    },
    methods: {
        doValueChange(selection: TreeNodeList) {
            if (selection.length == 1) {
                this.$emit("input", selection);
            }
        },
    },
    watch: {
        selection(newValue, oldValue) {
            if (newValue.length == 0) {
                this.selection = oldValue;
            }
        },
    },
});
</script>

<style>
.treeview-single-select .v-treeview-node__prepend {
    min-width: 0 !important;
}
</style>
