import store from "..";
import { Module, VuexModule, Mutation, getModule, Action } from "vuex-module-decorators";
import type { ServerInfo } from "timepowerweb-common/model/serverinfo-model";
import ServerInfoService from "../../services/serverinfo-service";

/**
 *
 */
@Module({
    name: "login",
    store,
    namespaced: true,
    dynamic: true,
})
class ServerInfoModule extends VuexModule {
    serverInfo: ServerInfo = {
        loginMethods: [],
        serverInstanceName: "",
        serverVersion: ""
    };

    @Action({ commit: "updateServerInfo" })
    retrieveServerInfo(): Promise<ServerInfo> {
        return ServerInfoService.getServerInfo();
    }

    @Mutation
    updateServerInfo(serverInfo?: ServerInfo) {
        this.serverInfo.loginMethods.length = 0;

        if (serverInfo?.loginMethods) {
            this.serverInfo.loginMethods.push(...serverInfo.loginMethods);
        }

        this.serverInfo.serverInstanceName = serverInfo?.serverInstanceName || "";
        this.serverInfo.serverVersion = serverInfo?.serverVersion || "";
    }
}

export default getModule(ServerInfoModule);
