<template>
    <v-main>
        <v-card
            class="pa-10 fill-height"
            align="center"
            :color="backgroundColor"
        >
            <transition
                name="router-animation"
                enter-active-class="animated fadeIn"
                leave-active-class="animated fadeOut"
                mode="out-in"
            >
                <router-view />
            </transition>
        </v-card>
    </v-main>
</template>

<script lang="ts">
import Vue from "vue";
import { colorMainBackground } from "../plugins/vuetify";

export default Vue.extend({
    name: "MainComponent",
    computed: {
        backgroundColor() {
            return colorMainBackground;
        },
    },
});

</script>
