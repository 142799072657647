<template>
    <reportingComponent />
</template>

<script lang="ts">
import Vue from "vue";
import ReportingComponent from "../components/reporting-component.vue";

export default Vue.extend({
    name: "ReportingView",
    components: {
        reportingComponent: ReportingComponent,
    },
});
</script>
