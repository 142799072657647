<template>
    <v-dialog persistent :value="true" max-width="700">
        <v-card>
            <v-card-title>{{
                $t("site.component.logout.titleDialog")
            }}</v-card-title>
            <v-card-text>
                <v-container fluid>
                    <v-row dense class="align-center">
                        <v-col> <v-icon x-large>mdi-alert </v-icon></v-col>
                        <v-col cols="10">
                            {{
                                $t("site.component.logout.textDialog", [
                                    appName,
                                ])
                            }}
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn color="secondary" @click="execute">
                    {{ $t("site.general.ok") }}
                </v-btn>
                <v-btn color="secondary" @click="cancel">
                    {{ $t("site.general.cancel") }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script lang="ts">
import Vue from "vue";
import { appName } from "timepowerweb-common/config";
import { logout } from "../helper/logout";

export default Vue.extend({
    name: "LogoutDialogView",
    data: () => ({
        appName: appName,
    }),
    methods: {
        execute() {
            logout();
        },
        cancel() {
            this.$router.go(-1);
        },
    },
});
</script>
