import Toast, { POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css";
import Vue from "vue";

const options = {
    // You can set your default options here
    position: POSITION.BOTTOM_CENTER,
    duration: 5000,
    dismissible: true,
    closeOnClick: true,
    maxToasts: 5,
    transition: "Vue-Toastification__fade",
    hideProgressBar: true,
};

Vue.use(Toast, options);
