<template>
    <v-card flat class="fill-height">
        <v-progress-linear indeterminate v-if="loading" />
        <div v-if="!loading" class="d-flex flex-column fill-height">
            <treeComponent
                :items="timepowerEntryTree"
                :open="timepowerEntryTreeOpen"
                v-model="timepowerEntrySelection"
            />
            <div class="mt-auto">
                <v-select
                    outlined
                    small
                    dense
                    block
                    :label="$t('object.event.workType')"
                    :items="workTypes"
                    hide-details
                    item-text="name"
                    item-value="id"
                    v-model="workType"
                    return-object
                >
                    <template slot="item" slot-scope="data">
                        <v-row no-gutters>
                            <v-col cols="3">
                                {{ data.item.name }}
                            </v-col>
                            <v-col>
                                <small>{{ data.item.comment }}</small>
                            </v-col>
                        </v-row>
                    </template>
                </v-select>
            </div>
        </div>
    </v-card>
</template>

<script lang="ts">
import Vue from "vue";
import TreeComponent from "./tree-component.vue";
import TableComponent from "./table-component.vue";
import CompanyModule from "../store/modules/company-module";
import { WorkType, WorkTypeList } from "timepowerweb-common/model/worktype-model";
import WorkTypeService from "../services/worktype-service";
import { InternalOrder } from "timepowerweb-common/model/internalorder-model";
import InternalOrderService from "../services/internalorder-service";
import { arrayToTree, filterEntryWithId, TreeNodeList } from "../helper/treetools";
import { EnumTreeType, getInternalOrderFromTimepowerEntry, TimepowerEntryList } from "timepowerweb-common/model/timepowerentry-model";
import { executeStatusChange } from "./internalorder-component.vue";
import { catClient } from "../helper/logging";

export default Vue.extend({
    name: "TimepowerEntryViewComponent",
    data: () => ({
        tab: 0,
        workType: new WorkType(),
        workTypes: [] as WorkTypeList,
        timepowerEntrySelection: [] as TreeNodeList,
        timepowerEntryTreeOpen: [] as TreeNodeList,
        timepowerEntryList: [] as TimepowerEntryList, // needed for lookup stuff in selection
        timepowerEntryTree: [] as TreeNodeList,
        internalOrder: new InternalOrder(),
        loading: true,
    }),
    computed: {
        company: () => CompanyModule.company,
    },
    components: {
        treeComponent: TreeComponent,
        tableComponent: TableComponent,
    },
    watch: {
        company: {
            handler(newValue, oldValue) {
                if (newValue !== oldValue) {
                    if (newValue.id == "") {
                        this.timepowerEntryList.length = 0;
                    } else {
                        this.loading = true;
                        InternalOrderService.getTimepowerEntryList(newValue)
                            .then((list) => {
                                this.timepowerEntryList = list;
                                const res = arrayToTree(this.timepowerEntryList);
                                this.timepowerEntryTree = res.treeNodes;
                                this.timepowerEntryTreeOpen = res.openedNodes;
                                return;
                            })
                            .catch((error) => catClient.error("companyHandler", error))
                            .finally(() => this.loading = false);
                    }
                }
            },
            immediate: true,
        },
        internalOrder: {
            handler(newValue, oldValue) {
                if (newValue !== oldValue) {
                    if (newValue.id === "") {
                        this.workTypes = [];
                    } else {
                        WorkTypeService.getWorkTypeList(newValue.id)
                            .then((worktypes) => {
                                this.workTypes = worktypes;
                                let workType = this.workTypes.find((x) => x.isDefault);
                                if (!workType) {
                                    workType = this.workTypes.find((x) => x.id === this.workType.id) ?? this.workTypes[0];
                                }
                                if (workType !== this.workType) {
                                    this.workType = workType;
                                } else {
                                    executeStatusChange(newValue, this.workType);
                                }
                                return;
                            })
                            .catch((error) => catClient.error("internalOrderHandler", error));
                    }
                }
            },
            immediate: true,
        },
        workType: {
            handler(newValue: WorkType) {
                executeStatusChange(this.internalOrder, newValue);
            },
            immediate: true,
        },
        timepowerEntrySelection: {
            handler(newValue: TreeNodeList) {
                const entry = this.timepowerEntryList.find((x) => filterEntryWithId(x, newValue[0].id));
                if (entry !== undefined) {
                    const costCenter = this.timepowerEntryList.find((x) => x.kind == entry.kind - 1 && x.id == entry.parent);
                    if (costCenter !== undefined
                        && entry.kind == EnumTreeType.InternalOrder) {
                        this.internalOrder = getInternalOrderFromTimepowerEntry(entry, this.company, costCenter);
                    }
                }
            },
            immediate: true,
        },
    },
});
</script>
