import { plainToInstance } from "class-transformer";
import { Report, ReportList, ReportParameterMap } from "timepowerweb-common/model/report-model";
import { ReportData } from "timepowerweb-common/model/reportdata-model";
import { REPORT_ROUTE } from "timepowerweb-common/routes";
import axios, { ComposedError } from "../helper/axios";

/**
 *
 */
export default class ReportService {
    static getReportList(): Promise<ReportList> {
        return axios.get(REPORT_ROUTE)
            .then((res) => plainToInstance(Report, res.data as []))
            .catch((error: ComposedError) => {
                // do not handle it globally because it will throw
                // an error toast on failed login
                throw error.handleGlobally("getReportList");
            });
    }

    static getReportData(report: Report, parameter: ReportParameterMap): Promise<ReportData> {
        return axios.post(REPORT_ROUTE + "/" + report.code, parameter)
            .then((res) => res.data)
            .catch((error: ComposedError) => {
                // do not handle it globally because it will throw
                // an error toast on failed login
                throw error.handleGlobally("getReportData");
            });
    }
}
