<template>
    <v-app-bar app>
        <v-app-bar-nav-icon
            :aria-label="textMenuButton"
            @click="isNavigationBarVisible = !isNavigationBarVisible"
        />

        <v-toolbar-title>
            {{ $t("site.title", [appName, leftHeadline]) }}
        </v-toolbar-title>

        <v-divider class="ma-2" />
        {{ rightHeadline }}
        <currentTimeComponent class="ml-2" />
        <v-tooltip
            v-if="loggedIn && (isNotificationFailure || isPepWarningRunFailure)"
            bottom
            :color="notificationColor"
        >
            <template v-slot:activator="{ on }">
                <v-icon class="ml-2" :color="notificationColor" v-on="on">
                    mdi-close-network-outline
                </v-icon>
            </template>
            <span>
                {{ notificationText }}
            </span>
        </v-tooltip>
    </v-app-bar>
</template>

<script lang="ts">
import Vue from "vue";
import CurrentTimeComponent from "./currenttime-component.vue";
import UserModule from "../store/modules/user-module";
import ServerInfoModule from "../store/modules/serverinfo-module";
import { appName } from "timepowerweb-common/config";
import { i18n } from "../plugins/i18n";

export default Vue.extend({
    name: "AppBarComponent",
    components: {
        currentTimeComponent: CurrentTimeComponent,
    },
    computed: {
        isNavigationBarVisible: {
            get() {
                return this.$store.state.isNavigationBarVisible;
            },
            set(val) {
                this.$store.commit("setNavigationBarVisible", val);
            },
        },
        loggedIn() {
            return UserModule.isLoggedIn;
        },
        isPepWarningRunFailure() {
            return this.$store.state.existsNetworkError;
        },
        isNotificationFailure() {
            return !(UserModule.user.settings.blockPepWarnings || Notification.permission === "granted");
        },
        notificationColor() {
            return this.$store.state.existsNetworkError ? "warning" : "error";
        },
        notificationText() {
            return this.$store.state.existsNetworkError ? i18n.t("site.component.appbar.notificationNetworkErrorHint", [appName]) : i18n.t("site.component.appbar.notificationDisabledInfo");
        },
        textMenuButton() {
            return this.$store.state.isNavigationBarVisible ? i18n.t("site.general.navigationNotVisible") : i18n.t("site.general.navigationVisible");
        },
        leftHeadline() {
            const instance = (ServerInfoModule.serverInfo.serverInstanceName ? (" (" + ServerInfoModule.serverInfo.serverInstanceName + ")") : "");
            return this.$store.state.appVersion + instance + " - " + (this.$route.meta?.title() ?? "");
        },
        rightHeadline() {
            return UserModule.userDisplayName;
        },
    },
    data: () => ({
        appName: appName,
    }),
});
</script>
