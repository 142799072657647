<template>
    <v-card flat max-width="500" color="transparent">
        <v-card class="mb-3">
            <v-card-title>
                <v-icon>mdi-account-cog-outline</v-icon>
                &nbsp; {{ $t("site.component.settings.userSettings") }}
            </v-card-title>
            <v-card-text>
                <v-switch
                    v-model="userSettings.quickModeEnabled"
                    :label="$t('object.user.settings.quickMode')"
                    :hint="$t('object.user.settings.quickModeHint')"
                    persistent-hint
                />
                <v-switch
                    v-model="userSettings.silentNotification"
                    :label="$t('object.user.settings.silentNotification')"
                    :hint="$t('object.user.settings.silentNotificationHint')"
                    persistent-hint
                />
            </v-card-text>

            <v-card-subtitle>
                {{ $t("site.component.settings.historyLimits") }}
            </v-card-subtitle>

            <v-card-text>
                <v-menu
                    :close-on-content-click="false"
                    transition="scale-transition"
                    min-width="auto"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            :value="
                                calculatedTime(userSettings.historyStartDate)
                            "
                            :label="$t('object.user.settings.historyStartDate')"
                            :hint="
                                $t('object.user.settings.historyStartDateHint')
                            "
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            persistent-hint
                        />
                    </template>
                    <v-date-picker
                        v-model="userSettings.historyStartDate"
                        no-title
                        scrollable
                        :locale="$i18n.locale"
                    />
                </v-menu>
            </v-card-text>
            <v-card-text>
                <v-text-field
                    v-model="userSettings.historyMaxDays"
                    :label="$t('object.user.settings.historyMaxDays')"
                    :hint="$t('object.user.settings.historyMaxDaysHint')"
                    prepend-icon="mdi-calendar-clock"
                    type="number"
                    persistent-hint
                />
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="submit" color="secondary">
                    {{ $t("site.general.save") }}
                </v-btn>
            </v-card-actions>
        </v-card>

        <passwordChangeComponent v-if="passwordChangeAvailable" />
    </v-card>
</template>

<script lang="ts">
import moment from "moment";
import Vue from "vue";
import { LoginMethodType } from "timepowerweb-common/model/serverinfo-model";
import PasswordChangeComponent from "../components/passwordchange-component.vue";
import { i18n } from "../plugins/i18n";
import ServerInfoModule from "../store/modules/serverinfo-module";
import UserModule from "../store/modules/user-module";

export default Vue.extend({
    name: "SettingsView",
    components: {
        passwordChangeComponent: PasswordChangeComponent,
    },
    computed: {
        userSettings() {
            return UserModule.user.settings;
        },
        locale() {
            return this.$i18n.locale;
        },
        passwordChangeAvailable() {
            return ServerInfoModule.serverInfo.loginMethods.find((x) => x.type === LoginMethodType.Intern) !== undefined;
        }
    },
    methods: {
        submit() {
            UserModule.updateUserSettings()
                .then(() => {
                    Vue.$toast.success(i18n.t("site.general.settingsSavedSuccessfully").toString());
                    return;
                })
                .catch(() => {
                    Vue.$toast.error(i18n.t("site.general.settingsSavingFailed").toString());
                });
        },
        calculatedTime(value: string) {
            return moment(value).format("L");
        },
    },
});
</script>
